async function sendTrackingEvent(tenantId , eventType, featureName = "Unknown",eventName="GSX", properties = {}) {
    try {
        if(!process.env.REACT_APP_ANALYTICS_ENABLE)
            return
        properties["eventType"]=`${eventType}`;
        properties["featureName"]=featureName;

        const data = {
            type: "track",
            event: `SpacesEvents - ${eventName}`,
            visitorId: (window.PointrWebSDK.MapWidget.getInstance()?.analyticsManager?.deviceId ? window.PointrWebSDK.MapWidget.getInstance()?.analyticsManager?.deviceId:"user_"+`${tenantId}`)+"_spaces.cisco.com",
            accountId: `${tenantId}_spaces.cisco.com`,
            timestamp: Date.now(),
            properties: properties,
            context: {
                ip: "", // Optionally replace with the actual IP
                userAgent: window.navigator.userAgent, // Browser's user agent
                url: window.location.href, // Current URL
                title: window.document.title // Current page title
            }
        };

        const response = await fetch('https://app.pendo.io/data/track', {
            method: 'POST',
            headers: {
                'x-pendo-integration-key': '1186bfe2-77b2-4fba-4b39-b46e29279f20', // Replace with your actual Pendo integration key
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
           // console.error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('Pendo Event Tracked Successfully:', responseData);

    } catch (error) {
       // console.error('Error tracking Pendo event:', error);
    }
}

export async function sendTrackingEventForMapClick(tenantId, feature, eventName) {
    const featureName = feature?.properties?.name || "Unknown";
    sendTrackingEvent(tenantId, 'MapClick', featureName, eventName);
}

export async function sendTrackingEventForZoomChange(tenantId, zoomValue, eventName) {
    const zoomLevel = zoomValue || "Unknown";
    sendTrackingEvent(tenantId, 'MapZoomChange', zoomLevel, eventName);
}

export async function sendTrackingEventForMapMoveEnd(tenantId, eventName) {
    sendTrackingEvent(tenantId, 'MapMove', "", eventName);
}

export async function sendTrackingEventForCiscoAI(tenantId="263253", userKey, eventType="Unknown", eventName="CLEMEA", properties = {}) {
    try {
        if(!process.env.REACT_APP_ANALYTICS_ENABLE)
            return
        properties["eventName"]=eventName;
        const data = {
            type: "track",
            event: `Cisco AI Assistant - ${eventType}`,
            visitorId: `${userKey}_spaces.cisco.com`,
            accountId: `${tenantId}_spaces.cisco.com`,
            timestamp: Date.now(),
            properties: properties,
            context: {
                ip: "", // Optionally replace with the actual IP
                userAgent: window.navigator.userAgent, // Browser's user agent
                url: window.location.href, // Current URL
                title: window.document.title // Current page title
            }
        };

        const response = await fetch('https://app.pendo.io/data/track', {
            method: 'POST',
            headers: {
                'x-pendo-integration-key': '1186bfe2-77b2-4fba-4b39-b46e29279f20', // Replace with your actual Pendo integration key
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
           // console.error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('Pendo Event Tracked Successfully:', responseData);

    } catch (error) {
       // console.error('Error tracking Pendo event:', error);
    }
}