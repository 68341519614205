import React, { useEffect, useState } from "react";
import heartIcon from '../../../../assets/images/ai/heart-icon.svg';
import ciscoAILogo from '../../../../assets/images/ai/ai-logo.svg';
import filterHeartIcon from '../../../../assets/images/ai/filter-like.svg';
import helpIcon from '../../../../assets/images/ai/help-icon.svg';
import locationIcon from '../../../../assets/images/ai/location-icon.svg';
import ciscoAiBackButton from '../../../../assets/images/ai/back-button.svg';
import heartIconFill from '../../../../assets/images/ai/heart-icon-fill.svg';
import greenDotIcon from '../../../../assets/images/ai/green-dot.svg';
import { calculateMidPoint } from '../main-container/markCurrentPosition'; // Import the functions
import ciscoClosebutton from '../../../../assets/images/ai/cisco-ai-close.svg';
import ciscoAiAudience from '../../../../assets/images/ai/audience-banner.svg';
import ciscoAiExhibitorAudience from '../../../../assets/images/ai/exhibitor-audience.svg';
import ciscoAiExhibitorBanner from '../../../../assets/images/ai/exhibitor-banner.svg';
import ciscoAiSessionAudience from '../../../../assets/images/ai/banner.svg';
import filterSelected from '../../../../assets/images/ai/selected-filter.svg';
import filterDefault from '../../../../assets/images/ai/default-filter.svg';
import faqDefault from '../../../../assets/images/ai/default-question.svg';
import faqSelected from '../../../../assets/images/ai/selected-question.svg';
import {stripHtml} from '../main-container/utilityMethods'
import {sendTrackingEventForCiscoAI} from "../main-container/pendoApis"

import moment from "moment";
import { fetchUserInterests, fetchRecommendedEvents, markExhibitorUserInterest, deleteSessionUserInterest, deleteExhibitorUserInterest, markSessionUserInterest, fetchExhibitorDetails, fetchSessionDetails } from "./ciscoAiApis";
import FAQ from "./ai-inline-faq-list";


const AIRecommendationsContainer = ({ showPopup, setShowAICard, recommendationsInfo, setRecommendationsInfo, attendeeUserToken, eventId, attendeeInfo, constraintsLookUp, teaLogService, tenantId, setShowFaqList, showFaqList, setPopupState,triggerDirectionsPopup, setTriggerDirectionsPopup, showAiLoader }) => {
    const [recommendations, setRecommendations] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedRecommendation, setSelectedRecommendation] = useState();
    const [favSelectedIndex, setFavSelectedIndex] = useState(-1);
    const [favSelectedRecommendation, setFavSelectedRecommendation] = useState(null);
    const [aiPrompts, setAiPrompts] = useState([]);
    const [aiPromptsMapping, setAiPromptsMapping] = useState([]);
    const [selectedAiPromptIndex, setSelectedAiPromptIndex] = useState(0);
    const [selectedAiPrompt, setSelectedAiPrompt] = useState();
    const [aiPromptSelected, setAiPromptSelected] = useState(false);
    const [sessionData, setSessionData] = useState({});
    const [exhibitorData, setExhibitorData] = useState([]);
    const [favoriteFilterSelected, setFavoriteFilterSelected] = useState(false);
    const [favoritesRecommendations, setFavoritesRecommendations] = useState([]);


    useEffect(() => {


        mergeRecommendations(recommendationsInfo)

        setSelectedIndex(0)
        setSelectedRecommendation(recommendations[selectedIndex])
        setFavSelectedIndex(-1)
        const aiPrompts = ["Show more networking activities", "Show more collaboration activities", "Show more security activities", "Show more observability activities", "Show more AI activities"];
        const aiPromptsMapping = ["Networking", "Collaboration", "Security", "Observability", "AI"];
        setAiPrompts(aiPrompts)
        setAiPromptsMapping(aiPromptsMapping)
        setSelectedAiPromptIndex(0)
        setAiPromptSelected(false);
        showAiLoader(false);
    }, []);

    const mergeRecommendations = (recommendationData) => {
        try {
            if (!recommendationData) {
                console.error("recommendationData is undefined or null");
                return [];
            }

            const sessions = (recommendationData?.recommended_schedule || [])
                .sort((a, b) => b.recommendationScore - a.recommendationScore)
                .slice(0, 10);

            const exhibitors = (recommendationData?.recommended_exhibitors || [])
                .sort((a, b) => b.recommendationScore - a.recommendationScore)
                .slice(0, 10);

            const demos = (recommendationData?.recommended_exhibitors || [])
                .filter(item => item.source === "demos")
                .sort((a, b) => b.recommendationScore - a.recommendationScore)
                .slice(0, 10);

            const mergedList = [ ...sessions, ...demos, ...exhibitors]
                .sort((a, b) => b.recommendationScore - a.recommendationScore);

            setRecommendations(mergedList);

        } catch (e) {
            console.error("Error in mergeRecommendations:", e);
            setRecommendations([]);
        }
    };
/**
    const mergeRecommendations = (recommendationData) => {
        
        // Add a 'source' property to schedule items
        const scheduleWithSource = recommendationData?.recommended_schedule.map(item => ({
            ...item,
            source: "schedule"
        }));
        if (!scheduleWithSource || !recommendationData?.recommended_exhibitors)
            return;

        // Combine the two arrays
        const combinedData = [...scheduleWithSource, ...recommendationData?.recommended_exhibitors];

        // Sort the combined array by recommendationScore in descending order
        const sortedData = combinedData.sort((a, b) => b.recommendationScore - a.recommendationScore);
        setRecommendations(sortedData);
    }
 */
    const fetchRecommendationsObject = (recommendationsObjects, id) => {
        return recommendationsObjects?.find(item => item.sessionId === id || item.exhibitorId === id) || null;
    };
    const batchSize = 10;

    // Function to split into batches
    const createBatches = (array, size) => {
        const batches = [];
        for (let i = 0; i < array.length; i += size) {
            batches.push(array.slice(i, i + size));
        }
        return batches;
    };
    useEffect(() => {
        // setExhibitorData([]);
        // setSessionData([]);
        fetchSessionData();
        fetchExhibitorData();
    }, [recommendations]);


    const fetchSessionData = async () => {
        const sessionIds = recommendations
            .filter((session) => session?.sessionId && !sessionData[session?.sessionId])
            .map((session) => session.sessionId);
        const batches = createBatches(sessionIds, batchSize);

        for (const batch of batches) {
            try {

                //  const queryParams = batch.map((id) => `sessionId=${id}`).join("&");
                // const data = await fetchSessionDetails(attendeeUserToken, eventId, queryParams, teaLogService)
                const queryParams = `${batch.join(",")}`;
                const data = await fetchSessionDetails(attendeeUserToken, tenantId, eventId, queryParams, teaLogService)

                // Update sessionData state with new information
                setSessionData((prevData) => {
                    // Create a mapping from sessionId to its respective object
                    const newSessionData = data?.reduce((acc, session) => {
                        if (session.sessionId) {
                            acc[session.sessionId] = session; // Map sessionId to the session object
                        }
                        return acc;
                    }, {});

                    // Merge with the previous data
                    return { ...prevData, ...newSessionData };
                });

            } catch (error) {
                console.error("Error fetching session data:", error);
            }
        }
    };

    const fetchExhibitorData = async () => {

        const exhibitorIds = recommendations
            .filter((exhibitor) => (exhibitor?.exhibitorId && !exhibitorData[exhibitor?.exhibitorId]))
            .map((exhibitor) => exhibitor?.exhibitorId);
        const batches = createBatches(exhibitorIds, batchSize);

        for (const batch of batches) {
            try {
                const queryParams = `${batch.join(",")}`;
                const data = await fetchExhibitorDetails(attendeeUserToken, tenantId, eventId, queryParams, teaLogService)

                setExhibitorData((prevData) => {
                    // Create a mapping from exhibitorId to its respective object
                    const newExhibitorData = data?.reduce((acc, exhibitor) => {
                        if (exhibitor.exhibitorId) {
                            acc[exhibitor.exhibitorId] = exhibitor; // Map exhibitorId to the exhibitor object
                        }
                        return acc;
                    }, {});

                    // Merge with the previous data
                    return { ...prevData, ...newExhibitorData };
                });
            } catch (error) {
                console.error("Error fetching session data:", error);
            }
        }
    };
    const handleGetDirections = async (destFid, data) => {
        if(triggerDirectionsPopup){
           // window.alert("Get Directions is already being shown for other booth, Please close the existing, if you want to see for other booth")
            return;
        }
        teaLogService.teaLogButtonAction("Get Directions " + destFid)
        sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0]?.userKey, "Get Directions","CLEMEA", data);

        const pointrWeb = window.PointrWebSDK.MapWidget.getInstance();

        const poi = await pointrWeb.getPoiManager().get(destFid);
        if (poi) {
            setPopupState({ poi: null, coordinates: null });
            // Uncomment this line when ready to show the popup
            const uiController = pointrWeb.getUiController();
            const mapViewController = uiController.getMapViewController();
            mapViewController.clearCachedHighlightedPois();
            mapViewController.setLevel(poi.properties.lvl);
            mapViewController.highlightPoi(poi);
            setPopupState({ poi, coordinates: (poi?.geometry?.type === 'Point') ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]) });
           // setTriggerDirectionsPopup(false);
            //showPopup(poi, poi?.geometry?.type === 'Point' ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]), false, true);
            setTriggerDirectionsPopup(true);
            handleClose();
        }
    }
    const handleSelect = (index) => {
        setSelectedIndex(index);
       // recommendation[index]
        const data={
            "title": recommendations[index]?.title,
            "type": recommendations[index]?.exhibitorId?"exhibitor":"session"
        }
        sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0]?.userKey, "Recommendation", "CLEMEA", data);

    };
    const handleFavSelect = (id) => {
        setFavSelectedIndex(id);
        const data={
            "title": favoritesRecommendations[id]?.title,
            "type":favoritesRecommendations[id]?.exhibitorId?"exhibitor":"session"
        }
        setFavSelectedRecommendation(favoritesRecommendations[id])
        sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0]?.userKey, "Recommendation", "CLEMEA", data);
    };

    const handleAiPromptSelect = async (index) => {

      //  teaLogService.teaLogPromptSubmissionAction(aiPrompts[index], "conversationId", "exchangeId")
        setSelectedAiPromptIndex(index);
        setSelectedAiPrompt(aiPrompts[index])
        setAiPromptSelected(true);
        var promptRecommendationsInfo = await fetchRecommendedEvents(attendeeUserToken, eventId, attendeeInfo, aiPromptsMapping[index], constraintsLookUp, teaLogService)
        if (!promptRecommendationsInfo)
            setShowAICard(false)
        //setRecommendationsInfo(recommendationsInfo);
        mergeRecommendations(promptRecommendationsInfo)
        setSelectedIndex(0)
        setSelectedRecommendation(recommendations[selectedIndex])
        setFavSelectedIndex(-1)

        sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0]?.userKey, "Prompt", "CLEMEA", {"Prompt Name":aiPrompts[index]});

    };

    const handleReturnToAiPrompts = () => {
        setAiPromptSelected(false);
        teaLogService?.teaLogButtonAction("Return to prompt");
    };

    const markAsExhibitorAsfavourite = (exhibitorId) => {
        if (favoritesRecommendations[exhibitorId]) {
            deleteExhibitorUserInterest(attendeeUserToken, eventId, attendeeInfo?.[0]?.userKey, favoritesRecommendations[exhibitorId], teaLogService)
        } else {
            markExhibitorUserInterest(attendeeUserToken, eventId, attendeeInfo?.[0]?.userKey, exhibitorData[exhibitorId], teaLogService)
        }

        setFavoritesRecommendations((prev) => {
            const updated = { ...prev }; // Create a shallow copy of the previous state
            if (updated[exhibitorId]) {
                delete updated[exhibitorId]; // Remove the sessionId from the object
            } else {
                updated[exhibitorId] = exhibitorData[exhibitorId] ? exhibitorData[exhibitorId] : {};
            }
            return updated; // Return the updated object to trigger re-render
        });

        console.log(favoritesRecommendations);
    }

    const markAsSessionAsfavourite = (sessionId) => {
        if (favoritesRecommendations[sessionId]) {
            deleteSessionUserInterest(attendeeUserToken, eventId, attendeeInfo?.[0]?.userKey, favoritesRecommendations[sessionId], teaLogService);
        } else {
            markSessionUserInterest(attendeeUserToken, eventId, attendeeInfo?.[0]?.userKey, sessionData[sessionId], teaLogService);
        }
        setFavoritesRecommendations((prev) => {
            const updated = { ...prev }; // Create a shallow copy of the previous state
            if (updated[sessionId]) {
                delete updated[sessionId]; // Remove the sessionId from the object
            } else {
                updated[sessionId] = sessionData[sessionId] ? sessionData[sessionId] : {};
            }
            return updated; // Return the updated object to trigger re-render
        });
        console.log(favoritesRecommendations);
    }

    const fetchUserInterestsFromUserKey = async () => {
        setShowFaqList(0);
        teaLogService.teaLogButtonAction("List Favourite Recommendations")
        setFavoriteFilterSelected(!favoriteFilterSelected);
        sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0]?.userKey, "Clicked Favourite Filter", "CLEMEA", {"operation":"favourite-filter-selected"});

    }
    useEffect(() => {
        async function fetchUserInterestsForUserKey() {
            if (!favoriteFilterSelected) {
                //  setFavoritesRecommendations({});
                // setFavSelectedRecommendation(null);
                setFavSelectedIndex(-1);
                setSelectedRecommendation(recommendations[selectedIndex])
                return;
            }

            const userInterests = await fetchUserInterests(attendeeUserToken, eventId, recommendationsInfo?.user);

            setFavoritesRecommendations({});

            const exhibitorIds = userInterests
                .filter((userInterest) => userInterest?.exhibitorId)
                .map((userInterest) => userInterest?.exhibitorId);

            console.log("exhibitorIds")
            console.log(exhibitorIds)
            const exhibitorBatches = createBatches(exhibitorIds, batchSize);

            for (const batch of exhibitorBatches) {
                try {
                    const queryParams = batch.map((id) => `${id}`).join(",");
                    const data = await fetchExhibitorDetails(attendeeUserToken, tenantId, eventId, queryParams, teaLogService);

                    setFavoritesRecommendations((prevData) => {
                        const newExhibitors = data?.reduce((acc, exhibitor) => {
                            if (exhibitor.exhibitorId) {
                                acc[exhibitor.exhibitorId] = exhibitor;
                            }
                            return acc;
                        }, {});


                        return {
                            ...prevData,
                            ...newExhibitors,
                        };
                    });
                } catch (error) {
                    console.error("Error fetching exhibitor data:", error);
                }
            }

            const sessionIds = userInterests
                .filter((userInterest) => userInterest?.sessionId)
                .map((userInterest) => userInterest?.sessionId);
            console.log("sessionIds")
            console.log(sessionIds)
            const batches = createBatches(sessionIds, 10);

            for (const batch of batches) {
                try {
                    //  const queryParams = batch.map((id) => `sessionId=${id}`).join("&");
                    //  const data = await fetchSessionDetails(attendeeUserToken, tenantId, eventId, queryParams, teaLogService);
                    const queryParams = `${batch.join(",")}`;
                    const data = await fetchSessionDetails(attendeeUserToken, tenantId, eventId, queryParams, teaLogService)

                    setFavoritesRecommendations((prevData) => {
                        const newSessions = data?.reduce((acc, session) => {
                            if (session.sessionId) {
                                acc[session.sessionId] = session;
                            }
                            return acc;
                        }, {});


                        return {
                            ...prevData,
                            ...newSessions,
                        };
                    });
                } catch (error) {
                    console.error("Error fetching session data:", error);
                }
            }


            handleFavSelect(favoritesRecommendations[0]?.sessionId ? favoritesRecommendations[0]?.sessionId : favoritesRecommendations[0]?.exhibitorId);
            // setFavSelectedRecommendation(favoritesRecommendations[0]);

        }

        fetchUserInterestsForUserKey();

    }, [favoriteFilterSelected]); // Trigger effect when favoriteFilterSelected changes

    useEffect(() => {
        setFavSelectedRecommendation(favoritesRecommendations[favSelectedIndex])
    }, [favSelectedIndex]);

    useEffect(() => {
        setSelectedRecommendation(recommendations[selectedIndex]);
        console.log(selectedIndex);
        console.log(selectedRecommendation);
    }, [selectedIndex]);


    const handleClose = () => {
        setShowAICard(false)
        // setRecommendationsInfo([]);
        setExhibitorData([])
        setSessionData([])
        setFavoritesRecommendations([])
        setShowFaqList(0);
    }

    const listFaqs = () => {
        if(showFaqList==2){
            setShowFaqList(0);
             //  setShowAICard(false);
            return;
        }
        setShowFaqList(2);
        if(favoriteFilterSelected)
        setFavoriteFilterSelected(!favoriteFilterSelected);
        sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0]?.userKey, "Clicked FAQ", "CLEMEA", {"operation":"question-selected"});

    }

    const calculateDuration = (sessionTimings) => {
        if (!sessionTimings?.length)
            return
        const hours = Math.floor(sessionTimings?.length / 60); // Calculate the number of hours
        const minutes = sessionTimings?.length % 60; // Calculate the remaining minutes

        let result = '';
        if (hours > 0) {
            result += `${hours} hr${hours > 1 ? 's' : ''}`; // Add 'hr' or 'hrs' based on the count
        }
        if (minutes > 0) {
            if (result) result += ' '; // Add space if hours already exist
            result += `${minutes} min`;
        }

        return result;
    }
    const calculateDurationLength = (sessionTimings) => {
        if (!sessionTimings)
            return
        const hours = Math.floor(sessionTimings / 60); // Calculate the number of hours
        const minutes = sessionTimings % 60; // Calculate the remaining minutes

        let result = '';
        if (hours > 0) {
            result += `${hours} hr${hours > 1 ? 's' : ''}`; // Add 'hr' or 'hrs' based on the count
        }
        if (minutes > 0) {
            if (result) result += ' '; // Add space if hours already exist
            result += `${minutes} min`;
        }

        return result;
    }

    const convertEpochToTime = (epoch) => {
        try {
            if (!epoch)
                return
            const date = new Date(epoch); // Convert milliseconds to Date
            const originalDate = date.toISOString().slice(0, 19).replace("T", " "); // Format as YYYY-MM-DD HH:mm:SS
            const formattedDate = moment(originalDate, "YYYY-MM-DD HH:mm:ss")
                .add(1, "hours")
                .format("h:mm A");//
            return formattedDate;
        } catch (error) {
            console.error('Fetch error:', error);

        }
        return ""
    };

    const convertEpochToDate = (epoch) => {
        try {
            if (!epoch)
                return
            const date = new Date(epoch); // Convert milliseconds to Date
            const originalDate = date.toISOString().slice(0, 19).replace("T", " "); // Format as YYYY-MM-DD HH:mm:SS
            const formattedDate = moment(originalDate, "YYYY-MM-DD HH:mm:ss")
                .add(1, "hours") // Add 13 hours and 30 minutes to adjust time
                .format("Do MMM, YYYY");//h:mm A
            return formattedDate;
        } catch (error) {
            console.error('Fetch error:', error);

        }
        return ""
    };

    return (

        <div className="right-ai-container-wrapper">

            <div className="right-ai-container-wrapper-title">
                <div className="right-ai-container-wrapper-title-text">Recommended Sessions</div>
                <img src={ciscoClosebutton} className="right-ai-container-wrapper-title-button-close" onClick={() => handleClose()} />
            </div>

            <div className="right-ai-container-wrapper-container-2">
                <div className="right-ai-container-wrapper-container-2-left">
                    <img src={ciscoAILogo} className="right-ai-container-wrapper-container-2-left-1" />
                    <div className="right-ai-container-wrapper-container-2-left-2">{"Hi " + attendeeInfo[0]?.firstName}</div>
                    <div className="right-ai-container-wrapper-container-2-left-3">Based on your historical data, I have found some recommendations you should visit. Please select one from the list or use the prompts on the right to select a specific area of interest.</div>
                    <div className="right-ai-container-wrapper-container-2-left-4">
                        <img src={showFaqList == 2 ? faqSelected : faqDefault} className="right-ai-container-wrapper-container-2-left-4-l" onClick={() => listFaqs()} />
                        <img src={favoriteFilterSelected ? filterSelected : filterDefault} className="right-ai-container-wrapper-container-2-left-4-r" onClick={() => fetchUserInterestsFromUserKey(recommendationsInfo?.user)} />
                    </div>

                </div>
                <div className="right-ai-container-wrapper-container-2-vertical-line"></div>

                <div className="right-ai-container-wrapper-container-2-right">
                    {aiPromptSelected ?
                        (<div className="right-ai-container-wrapper-container-2-right-selected-list">
                            <div className="right-ai-container-wrapper-container-2-right-middle">
                                <img src={greenDotIcon} className="right-ai-container-wrapper-container-2-right-middle-green-dot"></img>
                                <div className="right-ai-container-wrapper-container-2-right-middle-you">You</div>
                            </div>
                            <div
                                className="right-ai-container-wrapper-container-2-right-selected-list-item"
                            >
                                {aiPrompts[selectedAiPromptIndex]}
                            </div>
                            <div
                                className="right-ai-container-wrapper-container-2-right-selected-list-item-expand"
                            >
                                <div
                                    className="right-ai-container-wrapper-container-2-right-selected-list-item-expand-h"
                                >

                                    <img src={ciscoAILogo} className="right-ai-container-wrapper-container-2-right-selected-list-item-expand-h-left" />
                                    <div
                                        key={selectedAiPrompt}
                                        className="right-ai-container-wrapper-container-2-right-selected-list-item-expand-h-right"
                                    > AI Assistant</div>

                                </div>
                                <div
                                    className={`right-ai-container-wrapper-container-2-right-selected-list-item-expand-b`}>
                                    I’ve revised the recommended demos and sponsors based on your selection. Please select one from the list on the left or tap the “return to prompts” button below.
                                </div>
                            </div>
                            <div
                                className={`right-ai-container-wrapper-container-2-right-selected-list-return-item`}

                            >
                                <img
                                    src={ciscoAiBackButton}
                                    className={`right-ai-container-wrapper-container-2-right-selected-list-return-item-icon`}
                                    onClick={() => handleReturnToAiPrompts(false)}
                                />
                                <div
                                    key={selectedAiPrompt}
                                    className={`right-ai-container-wrapper-container-2-right-selected-list-return-item-label`}
                                    onClick={() => handleReturnToAiPrompts(false)}
                                >
                                    Return to prompts
                                </div>
                            </div>
                        </div>
                        ) :
                        (
                            <div>
                                <div className="right-ai-container-wrapper-container-2-right-top">
                                </div>
                                <div className="right-ai-container-wrapper-container-2-right-middle">
                                    <img src={greenDotIcon} className="right-ai-container-wrapper-container-2-right-middle-green-dot"></img>
                                    <div className="right-ai-container-wrapper-container-2-right-middle-you">You</div>
                                </div>
                                <div className="right-ai-container-wrapper-container-2-right-bottom">
                                    <div className="right-ai-container-wrapper-container-2-right-bottom-list">
                                        {aiPrompts.map((prompt, index) => (
                                            <div
                                                key={index}
                                                className={`right-ai-container-wrapper-container-2-right-bottom-list-item ${selectedAiPromptIndex === index ? "selected" : ""}`}
                                                onClick={() => handleAiPromptSelect(index)}>
                                                {prompt}
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

            </div>
            {showFaqList == 2 ? (
                <FAQ tenantId={tenantId} attendeeInfo ={attendeeInfo} setShowFaqList={setShowFaqList}/>
            ) : (
                (<div className="right-ai-container-wrapper-container-1">
                    {favoriteFilterSelected ? (
                        <>
                            <div className="right-ai-container-wrapper-container-1-left">
                                {Object.values(favoritesRecommendations)?.map((recommendation, index) => (
                                    <div
                                        key={index}
                                        className={`right-ai-container-wrapper-container-1-left-item ${(favSelectedIndex === (recommendation.exhibitorId ? recommendation.exhibitorId : recommendation.sessionId)) ? "selected" : ""}`}
                                        onClick={() => handleFavSelect(recommendation?.exhibitorId ? recommendation?.exhibitorId : recommendation?.sessionId)}>
                                        <div className="right-ai-container-wrapper-container-1-left-item-left" style={{ backgroundColor: recommendation?.exhibitorId ? "#F2CA00" : "#16BDEB" }} >
                                            {favoritesRecommendations && (
                                                <img
                                                    className="right-ai-container-wrapper-container-1-left-item-left-icon"
                                                    src={
                                                        recommendation?.exhibitorId
                                                            ? (favoritesRecommendations[recommendation?.exhibitorId]
                                                                ? heartIconFill
                                                                : heartIcon)
                                                            : (favoritesRecommendations[recommendation?.sessionId]
                                                                ? heartIconFill
                                                                : heartIcon)
                                                    }
                                                    onClick={() => recommendation?.exhibitorId ? markAsExhibitorAsfavourite(recommendation?.exhibitorId) : markAsSessionAsfavourite(recommendation?.sessionId)}
                                                />
                                            )}
                                        </div>

                                        <div className="right-ai-container-wrapper-container-1-left-item-right">
                                            <div className="right-ai-container-wrapper-container-1-left-item-right-title">
                                                {recommendation?.exhibitorId ? recommendation?.name : recommendation?.name}
                                            </div>
                                            <div className="right-ai-container-wrapper-container-1-left-item-right-sub-title">
                                                {recommendation?.exhibitorId ? recommendation?.type : recommendation?.subTitle}
                                            </div>
                                        </div>

                                        <div className="right-ai-container-wrapper-container-1-left-item-right-corner">
                                            {(favSelectedIndex === (recommendation.exhibitorId ? recommendation.exhibitorId : recommendation.sessionId)) && (
                                                <div className="right-ai-container-wrapper-container-1-left-item-right-corner-icon"></div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="right-ai-container-wrapper-container-1-right">
                                <div className="right-ai-container-wrapper-container-1-right-top" style={{
                                    backgroundImage: `url(${favSelectedRecommendation?.exhibitorId ? ciscoAiExhibitorBanner : ciscoAiSessionAudience})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}>
                                    <div className="right-ai-container-wrapper-container-1-right-top-title">

                                        {favSelectedRecommendation?.exhibitorId ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.boothType : favSelectedRecommendation?.sessionCode}
                                    </div>
                                    <div className="right-ai-container-wrapper-container-1-right-top-bridge"></div>


                                    <img src={favSelectedRecommendation?.exhibitorId ? ciscoAiExhibitorAudience : ciscoAiAudience} className="right-ai-container-wrapper-container-1-right-top-left-image" alt="Audience" />

                                </div>
                                <div className="right-ai-container-wrapper-container-1-right-bottom-banner-right">
                                            <img src={favSelectedRecommendation?.exhibitorId
                                                ? (favoritesRecommendations[favSelectedRecommendation?.exhibitorId]
                                                    ? heartIconFill
                                                    : heartIcon)
                                                : (favoritesRecommendations[favSelectedRecommendation?.sessionId]
                                                    ? heartIconFill
                                                    : heartIcon)} alt="Popup Content" className="right-ai-container-wrapper-container-1-right-bottom-banner-right-icon" onClick={() => favSelectedRecommendation?.exhibitorId ? markAsExhibitorAsfavourite(favSelectedRecommendation?.exhibitorId) : markAsSessionAsfavourite(favSelectedRecommendation?.sessionId)} />
                                        </div>
                                <div className="right-ai-container-wrapper-container-1-right-bottom">
                                    <div className="right-ai-container-wrapper-container-1-right-bottom-banner">
                                        <div className="right-ai-container-wrapper-container-1-right-bottom-banner-left">
                                            {favSelectedRecommendation?.exhibitorId ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.name : favSelectedRecommendation?.name}
                                        </div>
                                    </div>
                                    {(favSelectedRecommendation?.exhibitorId ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.startTime : convertEpochToDate(favoritesRecommendations[favSelectedRecommendation?.sessionId]?.utcStartTime))
                                    &&
                                    (<div className="right-ai-container-wrapper-container-1-right-bottom-timings">
                                    <div className="right-ai-container-wrapper-container-1-right-bottom-timings-left">
                                        {favSelectedRecommendation?.exhibitorId ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.startTime : convertEpochToDate(favoritesRecommendations[favSelectedRecommendation?.sessionId]?.utcStartTime)}
                                    </div>

                                    <div className="right-ai-container-wrapper-container-1-right-bottom-timings-right">
                                        <div className="right-ai-container-wrapper-container-1-right-bottom-timings-right-1">
                                            {favSelectedRecommendation?.exhibitorId
                                                ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.startTime
                                                : convertEpochToTime(favoritesRecommendations[favSelectedRecommendation?.sessionId]?.utcStartTime)}
                                        </div>
                                        <div className="right-ai-container-wrapper-container-1-right-bottom-timings-right-2">
                                            {favSelectedRecommendation?.exhibitorId
                                                ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.startTime
                                                : calculateDurationLength(favoritesRecommendations[favSelectedRecommendation?.sessionId])
                                            }
                                        </div>
                                    </div>
                                </div>)}
                                    
                                        {(favSelectedRecommendation?.exhibitorId
                                            ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.location
                                            : favoritesRecommendations[favSelectedRecommendation?.sessionId]?.location
                                        ) && (
                                            <div className="right-ai-container-wrapper-container-1-right-bottom-location">
                                                <img
                                                    src={locationIcon}
                                                    alt="Popup Content"
                                                    className="right-ai-container-wrapper-container-1-right-bottom-location-icon"
                                                />
                                                <div className="right-ai-container-wrapper-container-1-right-bottom-location-name">
                                                {favSelectedRecommendation?.exhibitorId
                                                    ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.location
                                                    : favoritesRecommendations[favSelectedRecommendation?.sessionId]?.location
                                                }
                                            </div>
                                               </div>
                                            )}
                                 
                                    <div className="right-ai-container-wrapper-container-1-right-bottom-bridge" />
                                    <div
                                        className="right-ai-container-wrapper-container-1-right-bottom-description"
                                    >
                                        {
                                            favSelectedRecommendation?.exhibitorId
                                                ? stripHtml(favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.description)
                                                : stripHtml(favoritesRecommendations[favSelectedRecommendation?.sessionId]?.description)
                                        }


                                        

                                            {
                                                favSelectedRecommendation?.exhibitorId ?
                                                    (favoritesRecommendations[favSelectedRecommendation?.exhibitorId]?.speakers?.map((speaker, index)=>(<div></div>))) :
                                                    (favoritesRecommendations[favSelectedRecommendation?.sessionId]?.speakers?.map((speaker, index) => (
                                                        <div className="right-ai-container-wrapper-container-1-right-bottom-description-speaker-section">
                                                        <div className="right-ai-container-wrapper-container-1-right-bottom-description-speaker-section-details">

                                                            <div className="right-ai-container-wrapper-container-1-right-bottom-description-speaker-section-details-header">
                                                                Speaker Name
                                                            </div>
                                                            <div className="right-ai-container-wrapper-container-1-right-bottom-description-speaker-section-details-value">
                                                                {speaker?.name}
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )))
                                            }

                                       
                                    </div>




                                </div>
                                    {(
                                        favoritesRecommendations[favSelectedRecommendation?.exhibitorId] ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId].poiFId : favoritesRecommendations[favSelectedRecommendation?.sessionId]?.poiFId
                                    ) && (
                                            <div className="right-ai-container-wrapper-container-1-right-get-directions" >
                                                <div className="right-ai-container-wrapper-container-1-right-get-directions-button"
                                                    onClick={() => handleGetDirections(
                                                        favoritesRecommendations[favSelectedRecommendation?.exhibitorId] 
                                                        ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId].poiFId
                                                        : favoritesRecommendations[favSelectedRecommendation?.sessionId]?.poiFId,
                                                    
                                                        favoritesRecommendations[favSelectedRecommendation?.exhibitorId] 
                                                        ? favoritesRecommendations[favSelectedRecommendation?.exhibitorId]
                                                        : favoritesRecommendations[favSelectedRecommendation?.sessionId]
                                                        )}>
                                                    Get Directions
                                                </div>
                                            </div>
                                        )}                    
                            </div>
                        </>


                    ) : (
                        <>
                            <div className="right-ai-container-wrapper-container-1-left">
                                {
                                    recommendations?.map((recommendation, index) => (
                                        <div
                                            key={index}
                                            className={`right-ai-container-wrapper-container-1-left-item ${selectedIndex === index ? "selected" : ""}`}
                                            onClick={() => handleSelect(index)}>
                                            <div className="right-ai-container-wrapper-container-1-left-item-left" style={{ backgroundColor: recommendation?.exhibitorId ? "#F2CA00" : "#16BDEB" }} >
                                                {recommendations && (
                                                    <img
                                                        className="right-ai-container-wrapper-container-1-left-item-left-icon"
                                                        src={
                                                            recommendation?.exhibitorId
                                                                ? (favoritesRecommendations[recommendation?.exhibitorId]
                                                                    ? heartIconFill
                                                                    : heartIcon)
                                                                : (favoritesRecommendations[recommendation?.sessionId]
                                                                    ? heartIconFill
                                                                    : heartIcon)
                                                        }
                                                        onClick={() => recommendation?.exhibitorId ? markAsExhibitorAsfavourite(recommendation?.exhibitorId) : markAsSessionAsfavourite(recommendation?.sessionId)}
                                                    />
                                                )}
                                            </div>
                                            <div className="right-ai-container-wrapper-container-1-left-item-right">
                                                <div className="right-ai-container-wrapper-container-1-left-item-right-title">
                                                    {recommendation?.exhibitorId ? recommendation?.name : recommendation?.title}
                                                </div>
                                                <div className="right-ai-container-wrapper-container-1-left-item-right-sub-title">
                                                    {recommendation?.exhibitorId ? recommendation?.type : recommendation?.subTitle}
                                                </div>
                                            </div>

                                            <div className="right-ai-container-wrapper-container-1-left-item-right-corner">
                                                {selectedIndex === index && (
                                                    <div className="right-ai-container-wrapper-container-1-left-item-right-corner-icon"></div> // Play button is only visible when selected
                                                )}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="right-ai-container-wrapper-container-1-right">
                                <div className="right-ai-container-wrapper-container-1-right-top" style={{
                                    backgroundImage: `url(${selectedRecommendation?.exhibitorId ? ciscoAiExhibitorBanner : ciscoAiSessionAudience})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: '100vh',   // Example height (full viewport height)
                                }}>
                                    <div className="right-ai-container-wrapper-container-1-right-top-title">
                                        {selectedRecommendation?.exhibitorId ? exhibitorData[selectedRecommendation?.exhibitorId]?.boothType : selectedRecommendation?.abbreviation}

                                    </div>
                                    <div className="right-ai-container-wrapper-container-1-right-top-bridge"></div>

                                    <img src={selectedRecommendation?.exhibitorId ? ciscoAiExhibitorAudience : ciscoAiAudience} className="right-ai-container-wrapper-container-1-right-top-left-image" alt="Audience" />

                                </div>
                                <div className="right-ai-container-wrapper-container-1-right-bottom-banner-right">
                                    <img src={selectedRecommendation?.exhibitorId
                                        ? (favoritesRecommendations[selectedRecommendation?.exhibitorId]
                                            ? heartIconFill
                                            : heartIcon)
                                        : (favoritesRecommendations[selectedRecommendation?.sessionId]  
                                            ? heartIconFill
                                            : heartIcon)} alt="Popup Content" className="right-ai-container-wrapper-container-1-right-bottom-banner-right-icon" onClick={() => selectedRecommendation?.exhibitorId ? markAsExhibitorAsfavourite(selectedRecommendation?.exhibitorId) : markAsSessionAsfavourite(selectedRecommendation?.sessionId)} />
                                </div>
                                <div className="right-ai-container-wrapper-container-1-right-bottom">
                                    <div className="right-ai-container-wrapper-container-1-right-bottom-banner">
                                        <div className="right-ai-container-wrapper-container-1-right-bottom-banner-left">
                                            {selectedRecommendation?.exhibitorId ? exhibitorData[selectedRecommendation?.exhibitorId]?.name : selectedRecommendation?.title}
                                        </div>

                                    </div>
                                    {(selectedRecommendation?.exhibitorId ? exhibitorData[selectedRecommendation?.exhibitorId]?.startTime : (convertEpochToDate(fetchRecommendationsObject(recommendations,selectedRecommendation?.sessionId)?.startDateTime)))
                                    &&
                                        (
                                        <div className="right-ai-container-wrapper-container-1-right-bottom-timings">
                                        <div className="right-ai-container-wrapper-container-1-right-bottom-timings-left">
                                            {selectedRecommendation?.exhibitorId ? exhibitorData[selectedRecommendation?.exhibitorId]?.startTime : (convertEpochToDate(fetchRecommendationsObject(recommendations,selectedRecommendation?.sessionId)?.startDateTime))}
                                        </div>
                                        <div className="right-ai-container-wrapper-container-1-right-bottom-timings-right">
                                            <div className="right-ai-container-wrapper-container-1-right-bottom-timings-right-1">
                                                {selectedRecommendation?.exhibitorId
                                                    ? exhibitorData[selectedRecommendation?.exhibitorId]?.startTime
                                                    : convertEpochToTime(fetchRecommendationsObject(recommendations,selectedRecommendation?.sessionId)?.startDateTime)}
                                            </div>
                                            <div className="right-ai-container-wrapper-container-1-right-bottom-timings-right-2">
                                                {selectedRecommendation?.exhibitorId
                                                    ? exhibitorData[selectedRecommendation?.exhibitorId]?.startTime
                                                    : calculateDurationLength(fetchRecommendationsObject(recommendations,selectedRecommendation?.sessionId)?.length)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                        )

                                    }
                                    
                                   
                                        {(selectedRecommendation?.exhibitorId
                                            ? exhibitorData[selectedRecommendation?.exhibitorId]?.location
                                            : sessionData[selectedRecommendation?.sessionId]?.location
                                        ) && (
                                            <div className="right-ai-container-wrapper-container-1-right-bottom-location">
                                                <img
                                                    src={locationIcon}
                                                    alt="Popup Content"
                                                    className="right-ai-container-wrapper-container-1-right-bottom-location-icon"
                                                />
 <div className="right-ai-container-wrapper-container-1-right-bottom-location-name">
                                            {selectedRecommendation?.exhibitorId
                                                ? exhibitorData[selectedRecommendation?.exhibitorId]?.location
                                                : sessionData[selectedRecommendation?.sessionId]?.location
                                            }
                                        </div>
                                        </div>
                                            )}

                                    <div className="right-ai-container-wrapper-container-1-right-bottom-bridge"></div>

                                    <div
                                        className="right-ai-container-wrapper-container-1-right-bottom-description"
                                    >
                                        {
                                            selectedRecommendation?.exhibitorId
                                                ? stripHtml(exhibitorData[selectedRecommendation?.exhibitorId]?.description)
                                                : stripHtml(sessionData[selectedRecommendation?.sessionId]?.description)
                                        }  <div className="right-ai-container-wrapper-container-1-right-bottom-speaker-section">

                                            {
                                                selectedRecommendation?.exhibitorId ?
                                                    (exhibitorData[selectedRecommendation?.exhibitorId]?.speakers?.map((speaker, index) => (
                                                        <div>
                                                        </div>
                                                    ))) :
                                                    (sessionData[selectedRecommendation?.sessionId]?.speakers?.map((speaker, index) => (
                                                        <div className="right-ai-container-wrapper-container-1-right-bottom-description-speaker-section-details">

                                                            <div className="right-ai-container-wrapper-container-1-right-bottom-description-speaker-section-details-header">
                                                                Speaker Name
                                                            </div>
                                                            <div className="right-ai-container-wrapper-container-1-right-bottom-description-speaker-section-details-value">
                                                                {speaker?.name}
                                                            </div>
                                                        </div>
                                                    )))
                                            }

                                        </div>

                                    </div>
                                    </div>

                                        {
                                        (exhibitorData[selectedRecommendation?.exhibitorId] ? exhibitorData[selectedRecommendation?.exhibitorId].poiFId : sessionData[selectedRecommendation?.sessionId]?.poiFId) 
                                        && 
                                        (
                                                <div className="right-ai-container-wrapper-container-1-right-get-directions" >
                                                    <div className="right-ai-container-wrapper-container-1-right-get-directions-button"
                                                        onClick={() => handleGetDirections(
                                                            exhibitorData[selectedRecommendation?.exhibitorId] 
                                                            ? exhibitorData[selectedRecommendation?.exhibitorId].poiFId
                                                            : sessionData[selectedRecommendation?.sessionId]?.poiFId,
                                                        
                                                          exhibitorData[selectedRecommendation?.exhibitorId] 
                                                            ? exhibitorData[selectedRecommendation?.exhibitorId]
                                                            : sessionData[selectedRecommendation?.sessionId]
                                                            )}>
                                                        Get Directions
                                                    </div>
                                                </div>
                                        )
                                        }                                                     
                            </div>
                        </>
                    )}

                </div>))
                }
        </div>)
        ;

}

export default AIRecommendationsContainer;