const moment = require('moment-timezone');

export function getStartAndEndOfDayInUTC(timezone='America/Los_Angeles',startDate) {
    // Current time in PST
    const now = moment().tz(timezone);
    
    let dateToUse = (now.date() < startDate.day)||(now.date() > startDate.day) ? startDate.day : now.date();
    const startOfDayPST = now.clone().date(dateToUse).month(startDate.month).startOf('day');

    // Start and end of the day in PST
    const endOfDayPST = startOfDayPST.clone().endOf('day');

    // Convert to UTC
    const startOfDayUTC = startOfDayPST.clone().utc();
    const endOfDayUTC = endOfDayPST.clone().utc();

    // Convert to epoch milliseconds
    const startOfDayUTCEpoch = startOfDayUTC.valueOf();
    const endOfDayUTCEpoch = endOfDayUTC.valueOf();

    console.log("Start of day UTC:", startOfDayUTCEpoch);
    console.log("End of day UTC:", endOfDayUTCEpoch);
    return { startEpochMilli: startOfDayUTCEpoch, endEpochMilli: endOfDayUTCEpoch };

}



export function getPDTDate(year, month, day) {
    // Create a date in UTC
    const utcDate = Date.UTC(year, month, day);

    // Manually adjust to PDT (UTC-7)
    const pdtOffset = 7 * 60 * 60 * 1000; // 7 hours in milliseconds
    //const pdtDate = new Date(utcDate.getTime() - pdtOffset);

    return utcDate - pdtOffset;
}

export function getPSTDateInUTC() {
    const currentDate = adjustDateTo27thOrCurrent();

    // Calculate the UTC time by subtracting the current timezone offset
    const utcTime = currentDate.getTime();

    // Calculate the PST offset (8 hours behind UTC) in milliseconds
    const pstOffset = 7 * 60 * 60 * 1000;

    // Adjust the UTC time to PST by subtracting the PST offset
    const pstDate = new Date(utcTime - pstOffset);

    // Convert the PST date back to UTC by adding the PST offset
    const utcFromPST = new Date(utcTime);

    const baseDay = 27;

    // Get the 27th of the current month
    const targetDate = new Date(Date.UTC(utcFromPST.getUTCFullYear(), utcFromPST.getUTCMonth(), baseDay));

    // Check if the current date is before the 27th
    if (utcFromPST < targetDate) {
        utcFromPST.setDate(baseDay);
    }
    // Get the start of the day in UTC
    const startOfDayUTC = Date.UTC(utcFromPST.getUTCFullYear(), utcFromPST.getUTCMonth(), utcFromPST.getUTCDate());

    // Get the end of the day in UTC (start of the next day minus 1 millisecond)
    const endOfDayUTC = startOfDayUTC + (24 * 60 * 60 * 1000) - 1;

    return {
        currentPST: pstDate.getTime(),
        utcFromPST: utcFromPST.getTime(),
        startOfDayUTC: startOfDayUTC,
        endOfDayUTC: endOfDayUTC,
    };
}
export function adjustDateTo27thOrCurrent() {
    const baseDay = 27;
    const currentDate = new Date();

    // Get the 27th of the current month
    const targetDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 27));

    // Check if the current date is before the 27th
    if (currentDate < targetDate) {
        currentDate.setDate(baseDay);
    }

    // Return the adjusted date
    return currentDate;
}
export function getPDTDateFromCurrent() {
    const currentDate = new Date();
    // Calculate the UTC time by subtracting the GMT+5:30 offset
    const utcTime = currentDate.getTime();

    // Calculate the PDT offset (7 hours behind UTC) in milliseconds
    const pdtOffset = 7 * 60 * 60 * 1000;

    // Adjust the UTC time to PDT by subtracting the PDT offset
    const pdtDate = new Date(utcTime - pdtOffset);
    return currentDate.getTime() - pdtOffset;
}
export function calculateEpochMillis() {
    const millis = getPSTDateInUTC();
    console.log(millis);
    return { startEpochMilli: millis.startOfDayUTC, endEpochMilli: millis.endOfDayUTC };
}
export function getEpochMilliForDate(dateInMillis) {
    console.log("date " + dateInMillis);
    const date = new Date(dateInMillis);

    // Get the UTC date

    // Calculate the UTC time for the date at midnight
    const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    const utcEndOfDay = utcDate + 24 * 60 * 60 * 1000 - 1; // End of day

    // Calculate the timezone offset in milliseconds for Los Angeles (PST/PDT)
    const losAngelesOffset = 7 * 60 * 60 * 1000; // PDT (UTC-7) during daylight saving time

    // Adjust for Los Angeles time
    const startEpochMilli = utcDate - losAngelesOffset;
    const endEpochMilli = utcEndOfDay - losAngelesOffset;

    return { startEpochMilli, endEpochMilli };

}

export function getStartEndInUTC() {
    
    // 1. Get the current date and time in UTC
    const currentDateUTC = new Date();

    // 2. Determine if we need to adjust the date
    const dayOfMonth = currentDateUTC.getUTCDate();
    const targetDay = dayOfMonth < 27 ? 27 : dayOfMonth;

    // 3. Create a new Date object for the target date
    const adjustedDateUTC = new Date(Date.UTC(
        currentDateUTC.getUTCFullYear(),
        currentDateUTC.getUTCMonth(),
        targetDay,
        0, 0, 0 // Set time to 00:00:00
    ));

    // 4. Get start and end of the adjusted day in UTC
    const startOfDayEpoch = adjustedDateUTC.getTime();
    const endOfDayUTC = new Date(Date.UTC(
        adjustedDateUTC.getUTCFullYear(),
        adjustedDateUTC.getUTCMonth(),
        adjustedDateUTC.getUTCDate() + 1, // Move to the next day
        0, 0, -1 // Set time to 23:59:59.999
    ));
    const endOfDayEpoch = endOfDayUTC.getTime();

    console.log("Start of Day Epoch:", startOfDayEpoch);
    console.log("End of Day Epoch:", endOfDayEpoch);

    return { startEpochMilli: startOfDayEpoch, endEpochMilli: endOfDayEpoch };

}

export function getStartEndInPST() {
    
    // 1. Get the current date and time in UTC
    const currentDateUTC = new Date();
    const losAngelesOffset = 7 * 60 * 60 * 1000; // PDT (UTC-7) during daylight saving time
    // 2. Determine if we need to adjust the date
    const dayOfMonth = currentDateUTC.getUTCDate();
    const targetDay = dayOfMonth < 27 ? 27 : dayOfMonth;

    // 3. Create a new Date object for the target date
    const adjustedDateUTC = new Date(Date.UTC(
        currentDateUTC.getUTCFullYear(),
        currentDateUTC.getUTCMonth(),
        targetDay,
        0, 0, 0 // Set time to 00:00:00
    ));

    // 4. Get start and end of the adjusted day in UTC
    const startOfDayEpoch = adjustedDateUTC.getTime()-losAngelesOffset;
    const endOfDayUTC = new Date(Date.UTC(
        adjustedDateUTC.getUTCFullYear(),
        adjustedDateUTC.getUTCMonth(),
        adjustedDateUTC.getUTCDate() + 1, // Move to the next day
        0, 0, -1 // Set time to 23:59:59.999
    ));
    const endOfDayEpoch = endOfDayUTC.getTime()-losAngelesOffset;

    console.log("Start of Day Epoch:", startOfDayEpoch);
    console.log("End of Day Epoch:", endOfDayEpoch);

    return { startEpochMilli: startOfDayEpoch, endEpochMilli: endOfDayEpoch };

}

export function getEndOftheDay () {
    const now = new Date();
    return new Date(now - (now % 86400000) + 86400000).getTime();
};