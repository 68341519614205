import React, { useState } from "react";
import "../../../../scss/faq.scss"; // Import your CSS
import ciscoAiBackButton from '../../../../assets/images/ai/back-button.svg';
import helpIcon from '../../../../assets/images/ai/help-icon.svg';
import ciscoClosebutton from '../../../../assets/images/ai/cisco-ai-close.svg';

const AIFAQListContainer = ({setShowFaqList, showFaqList, setShowAICard, showAICard, setShowPopupCard }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqData = [
    {
      question: "1. What is Cisco AI Assistant for Cisco Live?",
      answer: `Cisco AI Assistant delivers highly personalized session, demo, and exhibitor recommendations for registered event attendees.`
    },
    {
      question: "2. How does Cisco AI Assistant create session recommendations?",
      answer: `Cisco AI Assistant uses your technology interests collected at registration, session preferences, and previously attended sessions to generate recommendations to complete your agenda around your current schedule. If you are new to Cisco Live or have not attended a Cisco Live event in the last 2 years, your technology interests from your registration will help optimize recommendations. No personal information is used by the AI Assistant at any time.​`
    },
    {
      question: "3. How do I get started using Cisco AI Assistant?",
      answer: `You must be registered and logged in to your Cisco Live account to use the AI Assistant. To get started, go to the Session Catalog, and click the blue ‘Go now’ button. If you are new to Cisco Live or have not attended an event in the last two years, the AI Assistant will ask a few questions to help optimize the recommended sessions.​`
    },
    {
      question: "4. Why should I use Cisco AI Assistant for my Cisco Live agenda?",
      answer: `Cisco AI Assistant uses your technology interests collected at registration, session preferences, and previously attended sessions to generate recommendations to complete your agenda around your current schedule. If you are new to Cisco Live or have not attended a Cisco Live event in the last 2 years, the AI Assistant will ask three questions about session preferences to help optimize recommendations. No personal information is used by the AI Assistant at any time.​`
    },
    {
      question: "5. I need help with Cisco AI Assistant. Who can I reach out to?",
      answer: `You can email the Cisco Live Support team (ciscolive@ciscoevents.com) with your questions.`
    }
  ];
  

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-container-top">
        <img src={ciscoAiBackButton} className="faq-container-top-back-button" onClick={() =>{setShowFaqList(0);showFaqList==1?(setShowPopupCard(true)):(setShowAICard(true))} } />
        <div className="faq-container-top-title">Frequently Asked Questions</div>
        <img src={ciscoClosebutton} className="faq-container-top-close-button" onClick={() =>{setShowFaqList(0);showFaqList==1?(setShowPopupCard(true)):(setShowAICard(true))} } />

      </div>
      <div className="faq-container-bottom">
      {faqData.map((item, index) => (
        <div
          key={index}
          className={`faq-container-bottom-faq-item ${activeIndex === index ? "active" : ""}`}
          onClick={() => toggleAnswer(index)}
        >
          <div className="faq-container-bottom-faq-question">{item.question}</div>
          {activeIndex === index && (
      <div
        className="faq-container-bottom-faq-answer"
        dangerouslySetInnerHTML={{ __html: item.answer }}
      />
    )}
        </div>
      ))}
      </div>
    </div>
  );
};


export default AIFAQListContainer;