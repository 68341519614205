import { useState } from "react";

import ciscoClosebutton from '../../../../assets/images/ai/cisco-ai-close.svg';
import {sendTrackingEventForCiscoAI} from "../main-container/pendoApis"

const faqData = [
  {
    question: "What is Cisco AI Assistant for Cisco Live?",
    answer: `Cisco AI Assistant delivers highly personalized session, demo, and exhibitor recommendations for registered event attendees.`
  },
  {
    question: "How does Cisco AI Assistant create session recommendations?",
    answer: `Cisco AI Assistant uses your technology interests collected at registration, session preferences, and previously attended sessions to generate recommendations to complete your agenda around your current schedule. If you are new to Cisco Live or have not attended a Cisco Live event in the last 2 years, your technology interests from your registration will help optimize recommendations. No personal information is used by the AI Assistant at any time.​`
  },
  {
    question: "How do I get started using Cisco AI Assistant?",
    answer: `You must be registered and logged in to your Cisco Live account to use the AI Assistant. To get started, go to the Session Catalog, and click the blue ‘Go now’ button. If you are new to Cisco Live or have not attended an event in the last two years, the AI Assistant will ask a few questions to help optimize the recommended sessions.​`
  },
  {
    question: "Why should I use Cisco AI Assistant for my Cisco Live agenda?",
    answer: `Cisco AI Assistant uses your technology interests collected at registration, session preferences, and previously attended sessions to generate recommendations to complete your agenda around your current schedule. If you are new to Cisco Live or have not attended a Cisco Live event in the last 2 years, the AI Assistant will ask three questions about session preferences to help optimize recommendations. No personal information is used by the AI Assistant at any time.​`
  },
  {
    question: "I need help with Cisco AI Assistant. Who can I reach out to?",
    answer: `You can email the Cisco Live Support team (ciscolive@ciscoevents.com) with your questions.`
  }
];


export default function FAQ({setShowFaqList, tenantId, attendeeInfo}) {
  const [selected, setSelected] = useState(0);


  const handleClose = () => {
    setShowFaqList(0);
  }
  const handleFaqSelect = (index) =>{
    setSelected(index);
    sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0]?.userKey, "FAQ > Selected FAQ","CLEMEA", {"Question Title":faqData[index]?.question});
  }


  return (
    <div className="inline-faq-container">
      <div className="inline-faq-header"> 
      <div className="inline-faq-header-text">Frequently Asked Questions</div>
        <img src={ciscoClosebutton} className="inline-faq-header-button-close" onClick={() => handleClose()} />

      </div>
      <div className="inline-faq-bottom">
        <div className="inline-faq-sidebar">
          {faqData.map((item, index) => (
            <button
              key={index}
              className={`inline-faq-question ${selected === index ? "active" : ""}`}
              onClick={() => handleFaqSelect(index)}
            >
              {index + 1}. {item.question}
            </button>
          ))}
        </div>
        <div className="inline-faq-right-sidebar">
          <div className="inline-faq-content">
            <h3>{faqData[selected].question}</h3>
            <p>{faqData[selected].answer}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
