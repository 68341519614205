

export function markCurrentPosition(pointrWeb, long, lat, rotation,fly=true,pitch=65,bearing=-70 , whereAmIBuilding ,whereAmILevel ,building ,level, zoom=20.5,onlyReAddWhereAmI=false ) {
    try{
    if(!pointrWeb)
        return
    console.log("*****" + lat + " " + long + " " + rotation+" "+bearing+" "+pitch);
    lat = lat ? lat : 36.088308032921276;
    long = long ? long : -115.1782890405586;
    if(whereAmIBuilding!=building || whereAmILevel!=level){
        removeCurrentPosition(pointrWeb.getUiController().getMapViewController().getView());
       // manageLayers(pointrWeb?.getUiController().getMapViewController().getView(), building, level)
        return;
    }
    removeCurrentPosition(pointrWeb?.getUiController()?.getMapViewController()?.getView());
    const modelPosition = [long ? long : -115.1782890405586, lat ? lat : 36.088308032921276];
    const modelRotationAngle = [Math.PI / 2, rotation ? rotation : -4.5, 0];
    let customLayer = null;


    const modelOrigin = modelPosition;
    const modelAltitude = 0;
    const modelRotate = modelRotationAngle;
    let modelAsMercatorCoordinate = latLngAltToTransform(modelOrigin, modelAltitude, modelRotate);

   
    const modelTransform = {
        translateX: modelAsMercatorCoordinate.translateX,
        translateY: modelAsMercatorCoordinate.translateY,
        translateZ: modelAsMercatorCoordinate.translateZ,
        rotateX: modelAsMercatorCoordinate.rotateX,
        rotateY:modelAsMercatorCoordinate.rotateY,
        rotateZ:modelAsMercatorCoordinate.rotateZ,
        scale: modelAsMercatorCoordinate.scale
    };

    const THREE = window.THREE;
    customLayer = {
        id: '3d-model',
        type: 'custom',
        renderingMode: '3d',
        onAdd: function (map, gl) {
            this.camera = new THREE.Camera();
            this.scene = new THREE.Scene();

            const directionalLight = new THREE.DirectionalLight(0xffffff);
            directionalLight.position.set(0, 70, 70).normalize();
            this.scene.add(directionalLight);

            const light = new THREE.DirectionalLight(0xffffff);
            light.position.set(0, 70, -70).normalize();
            this.scene.add(light);

            const ambientLight = new THREE.AmbientLight(0xffffff, 0.55); // The second parameter is the intensity
            this.scene.add(ambientLight);

            const loader = new THREE.GLTFLoader();
            loader.load(
                '/spot-signage.gltf',
                (gltf) => {
                    this.scene.add(gltf.scene);
                }
            );
            this.map = map;

            this.renderer = new THREE.WebGLRenderer({
                canvas: map.getCanvas(),
                context: gl,
                antialias: true
            });

            this.renderer.autoClear = false;
        },
        render: function (gl, matrix) {
            const rotationX = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(1, 0, 0),
                modelTransform.rotateX
            );
            const rotationY = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 1, 0),
                modelTransform.rotateY
            );
            const rotationZ = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 0, 1),
                modelTransform.rotateZ
            );

            const m = new THREE.Matrix4().fromArray(matrix);
            const l = new THREE.Matrix4()
                .makeTranslation(
                    modelTransform.translateX,
                    modelTransform.translateY,
                    modelTransform.translateZ
                )
                .scale(
                    new THREE.Vector3(
                        modelTransform.scale,
                        -modelTransform.scale,
                        modelTransform.scale
                    )
                )
                .multiply(rotationX)
                .multiply(rotationY)
                .multiply(rotationZ);

            this.camera.projectionMatrix = m.multiply(l);
            this.renderer.resetState();
            this.renderer.render(this.scene, this.camera);
            this.map.triggerRepaint();
        }
    };
    const mapView = pointrWeb.getUiController().getMapViewController().getView();
    const latlng = new window.PointrWebSDK.LatLng({ lat: lat, lng: long });
         manageLayers(mapView, null, whereAmILevel);
    mapView.addLayer(customLayer,"rmaps-zone-marker");
    showLabel(mapView,modelPosition);
    if(fly){
  
       mapView.map.flyTo({
        center: [long,lat],
        zoom: zoom, pitch: pitch, bearing: bearing, speed: 0.4
      });
    }
    }   catch (error) {
        console.error("An error occurred in markCurrentPosition:", error);
    }
}


function latLngAltToTransform(modelOrigin, altitude,modelRotate) {
    
    // Constants
    const R = 6378137; // Radius of the Earth in meters

    // Convert latitude and longitude from degrees to radians
    const latRad = (modelOrigin[1] * Math.PI) / 180;
    const longRad = (modelOrigin[0] * Math.PI) / 180;

    // Calculate the Mercator x and y coordinates
    const x = R * longRad;
    const y = R * Math.log(Math.tan(Math.PI / 4 + latRad / 2));

  // Normalize the coordinates
  const translateX = (x + R * Math.PI) / (2 * R * Math.PI);
  const translateY = 0.5 - (y / (2 * R * Math.PI));

    // Define the transformation object
    const transform = {
        translateX: translateX,
        translateY: translateY,
        translateZ: altitude,  // Assuming altitude is already in the desired units
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        scale: 150.091301692798277e-8
    };

    return transform;
}

export function showLabel(map,modelPosition) {
    if (map.getLayer('rmaps-where-am-i-label')) return;

    let data = {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                properties: {
                    sizerank: 1,
                },
                geometry: {
                    type: "Point",
                    coordinates: modelPosition,
                },
            },
        ],
    }
    if(!map.map.getSource("rmaps-where-am-i-label-source")){
        map.map.addSource("rmaps-where-am-i-label-source", {
            type: "geojson",
            data: data
        });
    }

       
    

    map.addLayer({
      id: 'rmaps-where-am-i-label',
      type: 'symbol',
      source: 'rmaps-where-am-i-label-source',
      paint: {
        'text-color': '#fff'
      },
      layout: {
        'icon-allow-overlap': true,
        'text-allow-overlap': true,

        'text-font': [
            'DIN Pro Bold'
        ],
        'text-size': [
            'match',
            [
                'id'
            ],
            -2,
            8,
            8
        ],
        'icon-image': 'youarehere-new',
        'icon-text-fit': 'both',
        'icon-text-fit-padding': [12, 6, 32, 42], // Increased padding to fit text inside the icon
        'text-offset': [
            'step',
            ['zoom'],
            [
                'step',
                ['get', 'sizerank'],
                ['literal', [6, -2]],
                20,
                ['literal', [6, -10]]
            ],
            15,
            [
                'step',
                ['*', ['get', 'sizerank'], ['zoom']],
                ['literal', [6, -6]],
                16,
                ['literal', [6, -6]],
                17,
                ['literal', [6, -6]],
                18,
                ['literal', [6, -6]],
                19,
                ['literal', [6, -9]],
                20,
                ['literal', [6, -12]],
                21,
                ['literal', [6, -16]],
                22,
                ['literal', [6, -25]]
            ]
        ],
        'text-anchor': [
            'step',
            ['zoom'],
            ['step', ['get', 'sizerank'], 'center', 5, 'top'],
            19,
            ['step', ['get', 'sizerank'], 'center', 13, 'top']
        ],
        'text-field': 'You are here',
        'icon-keep-upright': true,
       
        'icon-padding': 2,
        'icon-optional': false,
        'icon-allow-overlap': false,
        'symbol-avoid-edges': false,
        'text-allow-overlap': false,
        'icon-offset':[0,-3]
      },
      minzoom:18,
      maxzoom:22
    });
  }

export function processLvlChange(map, level) {
    map.getStyle().layers.filter((layer) => {
        if (layer.id.startsWith("rmaps-")) {
            let filter = layer.filter;
            if (filter) {
                let lastFilter = layer.filter.pop();
                if (lastFilter && lastFilter.includes("lvl")) {
                    filter.push(["==", "lvl", level]);
                    map.removeLayer(layer.id);
                    map.addLayer(layer);
                } else {
                    filter.push(lastFilter);
                }
            }
        }
    });
    markCurrentPosition(map);
}


export function manageLayers(mapView, newBid, newlevel) {
    try {
    let rMapLayers = mapView?.getAllLayers()?.filter(item => item.id.startsWith('rmaps-'))
    let quickLayers =  mapView?.getAllLayers()?.filter(item => item.id.startsWith('symbol_quick-access'))
    const combinedLayers = rMapLayers?.concat(quickLayers)

    combinedLayers.forEach(layer => {
        if (layer.id.includes("-highlight")) {
            layer.layout.visibility = "none";
          } 
        if (layer.source !== "visualizer") {
            let filters = layer.filter
           // console.log(layer.id, filters)

            if (filters) {
                let lastFilter = filters.pop();

                if (!(lastFilter.includes("lvl") && lastFilter.includes("=="))) {
                    filters.push(lastFilter);
                } else {
                   
                    let buildingFilter =  filters.pop();
                    if (!(buildingFilter.includes("bid") && buildingFilter.includes("=="))) {
                        filters.push(buildingFilter);
                    }
                    if(newBid){
                    filters.push(["==", "bid", parseInt(newBid)])
                    }
            
                    filters.push(["==", "lvl", parseInt(newlevel)])
                }
            }
            mapView.removeLayer(layer["id"])
            mapView.addLayer(layer)
        }
    });
    } catch (error) {
        console.warn("An error occurred while processing layers in markCurrentPosition:", error);
    }

 //   markCurrentPosition(window.PointrWebSDK.MapWidget.getInstance());
}


export function highlightFloor(uiController, mapView, levelIndex) {
    let levels;
    mapView.on(mapView.events.currentBuildingChanged, (event) => {
        if (event.newCurrentBuilding !== undefined) {
            levels = event.newCurrentBuilding?.levels;
        }
        const filteredLevels = levels.filter(level => level.levelIndex === levelIndex);
        const levelObject = new window.PointrWebSDK.Level(filteredLevels[0]);

        uiController.setLevel(levelObject);
    });

}

export function highlightSite(pointrWeb, sites, siteId) {
    const SITE_OBJECT= sites.filter(building => building.siteInternalIdentifier === siteId); 
    const obj=new window.PointrWebSDK.Site(SITE_OBJECT[0]);
   pointrWeb.loadAndSet(obj);
   pointrWeb.uiController.goToSite(SITE_OBJECT[0].siteInternalIdentifier)

}
export function highlightBuildingFloor(pointrWeb, buidlings, buidlingId, levelIndex) {
    const BUILDING_OBJECT= buidlings.filter(building=> building.buildingInternalIdentifier === buidlingId); 
    const filteredLevels = BUILDING_OBJECT[0].levels.filter(level => level.levelIndex === levelIndex);
    const levelObject = new window.PointrWebSDK.Level(filteredLevels[0]);
    const obj=new window.PointrWebSDK.Building(BUILDING_OBJECT[0]);
   pointrWeb.loadAndSet(obj,levelObject);
   pointrWeb.uiController.goToBuilding(BUILDING_OBJECT[0].buildingInternalIdentifier)

}

export function removeCurrentPosition(map) {
    try {
        if (map) {
            if (map?.getLayer("3d-model"))
                map.removeLayer("3d-model");
            if (map?.getLayer("rmaps-where-am-i-label"))
                map.removeLayer("rmaps-where-am-i-label");
        }
    } catch (error) {
        console.warn("An error occurred while processing layers in handleReset:", error);
    }
}

export function hideCurrentPosition(map) {
    try {
        if (map) {
            if (map?.getLayer("3d-model"))
                map.hideLayer("3d-model");
            if (map?.getLayer("rmaps-where-am-i-label"))
                map.hideLayer("rmaps-where-am-i-label");
        }
    } catch (error) {
        console.warn("An error occurred while processing layers in handleReset:", error);
    }
}
export function unHideCurrentPosition(map) {
    try {
        if (map) {
            if (map?.getLayer("3d-model"))
                map.showLayer("3d-model");
            if (map?.getLayer("rmaps-where-am-i-label"))
                map.showLayer("rmaps-where-am-i-label");
        }
    } catch (error) {
        console.warn("An error occurred while processing layers in handleReset:", error);
    }
}

export function calculateMidPoint(coordinates) {
    if (coordinates.length === 0) return null;

    let firstCoordinate = coordinates[0];
    let minX = firstCoordinate[0];
    let maxX = firstCoordinate[0];
    let minY = firstCoordinate[1];
    let maxY = firstCoordinate[1];

    coordinates.forEach(coordinate => {
        minX = coordinate[0] < minX ? coordinate[0] : minX;
        maxX = coordinate[0] > maxX ? coordinate[0] : maxX;
        minY = coordinate[1] < minY ? coordinate[1] : minY;
        maxY = coordinate[1] > maxY ? coordinate[1] : maxY;
    });

    return {
        lat: (minY + maxY) / 2,
        lng: (minX + maxX) / 2
    };
}