import { InterestOperation,InterestType } from '@events/tealium';
import { v4 as uuidv4 } from 'uuid';
import {sendTrackingEventForCiscoAI} from "../main-container/pendoApis"

export async function fetchAttendeeInfo(accessToken, eventId, params = {},tealogServices) {

    const queryString = new URLSearchParams(params).toString();
    const apiUrl = `${process.env.REACT_APP_CISCO_EVENTS_API_URL}/api/e/${eventId}/user/all?${queryString}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + accessToken
            },
        });

        if (!response.ok) {
            tealogServices.teaLogExceptionOccurrence(response.status,response.body,"Not a expected response","Invalid response for Attendee Info","fetchAttendeeInfo trigger");
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("attendee Info " + data.data);

        return data?.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        
        tealogServices.teaLogExceptionOccurrence("400",error.message,"Exception","fetching Attendee Info failed","fetchAttendeeInfo trigger");
      //  return [];
        throw error;
    }
}

export async function fetchUserInterests(accessToken, eventId, userKey) {
    const apiUrl = `${process.env.REACT_APP_CISCO_EVENTS_API_URL}/api/e/${eventId}/interest/user-key/${userKey}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + accessToken
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Attendee Interest Info " + data?.data);

        sendTrackingEventForCiscoAI("263253", userKey, "Fetch Favourites","CLEMEA",{"eventId":eventId,"userKey":userKey});
        
        return data?.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
}

export async function markSessionUserInterest(accessToken, eventId, userKey, sessionInfo,tealogServices) {
    if(!sessionInfo)
        return
    const apiUrl = `${process.env.REACT_APP_CISCO_EVENTS_API_URL}/api/e/${eventId}/interest/ds1/user-key/${userKey}/interest-type/sessionInterest/id/${sessionInfo.sessionId}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + accessToken
            },
        });

        if (!response.ok) {
            tealogServices.teaLogExceptionOccurrence(response.status,response.body,"Not a expected response","Invalid response for MarkSession UserInterest","markSessionUserInterest trigger");
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data={
            "sessionTimeId": sessionInfo.sessionTimeId ,
            "sessionId": sessionInfo.sessionId ,
            "type": InterestType.sessionInterest,
            "title": sessionInfo.title,
            "name": sessionInfo.name,
            "abbreviation": sessionInfo.abbreviation
        };
       tealogServices.teaLogExpressInterestAction(
        InterestOperation.favorite,
        data,
        true);

        sessionInfo["type"] = "session";
        sessionInfo["operation"] = "favorite";

        sendTrackingEventForCiscoAI("263253", userKey, "Favourite","CLEMEA",sessionInfo);

    } catch (error) {
        console.error('Error fetching data:', error);
        tealogServices.teaLogExceptionOccurrence("400",error.message,"Exception occured","mark session userInterest Info failed","markSessionUserInterest trigger");

        return [];
    }
}
export async function deleteSessionUserInterest(accessToken, eventId, userKey, sessionInfo, tealogServices) {
    if(!sessionInfo)
        return
    const apiUrl = `${process.env.REACT_APP_CISCO_EVENTS_API_URL}/api/e/${eventId}/interest/ds1/user-key/${userKey}/interest-type/sessionInterest/id/${sessionInfo.sessionId}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + accessToken
            },
        });

        if (!response.ok) {
            tealogServices.teaLogExceptionOccurrence(response.status,response.body,"Not a expected response","Invalid response for Delete Session UserInterest","deleteSessionUserInterest trigger");
            throw new Error(`HTTP error! Status: ${response.status}`);
        }


      tealogServices.teaLogExpressInterestAction(
        InterestOperation.unfavorite,
        {
            "sessionTimeId": sessionInfo.sessionTimeId ,
            "sessionId": sessionInfo.sessionId ,
            "type": InterestType.sessionInterest,
            "title": sessionInfo.title,
            "name": sessionInfo.name,
            "abbreviation": sessionInfo.abbreviation
        },
        true);

        sessionInfo["type"] = "session";
        sessionInfo["operation"] = "unfavorite";

        sendTrackingEventForCiscoAI("263253", userKey, "Unfavourite", "CLEMEA" ,sessionInfo);

    } catch (error) {
        console.error('Error fetching data:', error);
        tealogServices.teaLogExceptionOccurrence("400",error.message,"Exception occured","delete session userInterest Info failed","deleteSessionUserInterest trigger");
        
        return [];
    }
}

export async function markExhibitorUserInterest(accessToken, eventId, userKey, exhibitorInfo, tealogServices) {
    if(!exhibitorInfo)
        return
    const apiUrl = `${process.env.REACT_APP_CISCO_EVENTS_API_URL}/api/e/${eventId}/interest/ds1/user-key/${userKey}/interest-type/exhibitorInterest/id/${exhibitorInfo.exhibitorId}`;
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + accessToken
            },
        });

        if (!response.ok) {
            tealogServices.teaLogExceptionOccurrence(response.status,response.body,"Not a expected response","Invalid response for Mark Exhibitor UserInterest","markExhibitorUserInterest trigger");
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        tealogServices.teaLogExpressInterestAction(
        InterestOperation.favorite,
        {
            "exhibitorID":exhibitorInfo.exhibitorID,
            "type": InterestType.exhibitorInterest,
            "title": exhibitorInfo.title,
            "name": exhibitorInfo.name,
            "abbreviation": exhibitorInfo.abbreviation
        },
        true);

        exhibitorInfo["type"] = "exhibitor";
        exhibitorInfo["operation"] = "favorite";

        sendTrackingEventForCiscoAI("263253", userKey, "Favourite", "CLEMEA" ,exhibitorInfo);
    } catch (error) {
        console.error('Error fetching data:', error);
        tealogServices.teaLogExceptionOccurrence("400",error.message,"Exception occured","mark exhibitor userInterest Info failed","markExhibitorUserInterest trigger");
        return [];
    }
}

export async function deleteExhibitorUserInterest(accessToken, eventId, userKey, exhibitorInfo, tealogServices) {
    if(!exhibitorInfo)
        return
    const apiUrl = `${process.env.REACT_APP_CISCO_EVENTS_API_URL}/api/e/${eventId}/interest/ds1/user-key/${userKey}/interest-type/exhibitorInterest/id/${exhibitorInfo.exhibitorId}`;
    try {
        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + accessToken
            },
        });

        if (!response.ok) {
            tealogServices.teaLogExceptionOccurrence(response.status,response.body,"Not a expected response","Invalid response for Delete Exhibitor UserInterest","deleteExhibitorUserInterest trigger");
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        tealogServices.teaLogExpressInterestAction(
            InterestOperation.unfavorite,
            {
                "exhibitorID":exhibitorInfo.exhibitorID,
                "type": InterestType.exhibitorInterest,
                "title": exhibitorInfo.title,
                "name": exhibitorInfo.name,
                "abbreviation": exhibitorInfo.abbreviation
            },
            true);
       // const data = await response.json();
       // console.log("Attendee Exhibitor Interest marked " + data);

    // return data?.data;

    exhibitorInfo["type"] = "exhibitor";
    exhibitorInfo["operation"] = "unfavorite";

    sendTrackingEventForCiscoAI("263253", userKey, "Unfavourite", "CLEMEA" ,exhibitorInfo);

    } catch (error) {
        console.error('Error fetching data:', error);
        tealogServices.teaLogExceptionOccurrence("400",error.message,"Exception occured","delete exhibitor userInterest Info failed","deleteExhibitorUserInterest trigger");
        return [];
    }
}


export async function fetchRecommendedEvents(accessToken, eventId, attendeeInfo, promptName = null,constraintsLookUp, teaLogServices) {
    const apiUrl = `${process.env.REACT_APP_CISCO_AI_API_URL}/events-api/api/v3.1/recommend_events`;

    try {
        const user = attendeeInfo?.[0]?.userKey;
        const scheduleAccess = attendeeInfo?.[0]?.scheduleAccess;

        const userPreferences = {
            "Learning Track": promptName ? [promptName] : [],
            "Learning Topics": [],
            "Session Types": [],
            "Days": [],
            "scheduleAccess": scheduleAccess
        };

        const data = {
            app_id: "ciscoevents",
            event_type: "ciscolive",
            request_source: "web",
            event_id: eventId,
            chat_conversation_id: uuidv4(),
            chat_request_id: "15455224186270017tXU1733420183587",
            is_return_user: true,
            user: user,
            user_preferences: [userPreferences],
            package_type: constraintsLookUp?getPackageType(attendeeInfo?.[0]?.dashboardPackage, constraintsLookUp):[],
            rejected_sessions: [],
            recommendation_slots: [],
            blocked_slots: [],
            rejected_exhibitors: []
        };

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            teaLogServices?.teaLogExceptionOccurrence(response?.status,response?.body,"Not a expected response","Invalid response for Fetching user Recommendations","fetchRecommendedEvents trigger");
            console.error(`HTTP error! status: ${response.status}`);
            return;
        }

        const responseData = await response.json();
        console.log('Recommend Events Response:', responseData);
        teaLogServices?.teaLogRecommendationReceiptEvent(responseData,data.chat_conversation_id)
        teaLogServices?.teaLogPromptSubmissionAction(promptName, data.chat_conversation_id, data.chat_request_id)
        return responseData;
    } catch (error) {
        console.error('Error fetching recommended events:', error);
        teaLogServices?.teaLogExceptionOccurrence("400",error.message,"Exception occured","fetchRecommendedEvents Info failed","fetchRecommendedEvents trigger");

    }
}

const getPackageType = (packages, lookup) => {
    if(!packages){
        return [];
    }
    return packages.map(pkg => {
      if (lookup[pkg?.trim()]) {
        return {
          [pkg?.trim()]: lookup[pkg?.trim()]
        };
      }
      return {}; 
    });
  };

/**
export async function fetchSessionDetails(accessToken, eventId, sessionIds, teaLogServices) {
    const apiUrl = `${process.env.REACT_APP_CISCO_EVENTS_API_URL}/api/e/${eventId}/session?onlyApprovedAndPublished=true&${sessionIds}`;
    try {
        

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            teaLogServices.teaLogExceptionOccurrence(response.status,response.body,"Not a expected response","Invalid response for Fetching session details for "+sessionIds,"fetchSessionDetails trigger");
            console.error(`HTTP error! status: ${response.status}`);
            return;
        }

        const responseData = await response.json();
        console.log('Session Details Response:', responseData);
        return responseData.data;
    } catch (error) {
        console.error('Error fetching session details:', error);
        teaLogServices?.teaLogExceptionOccurrence("400",error.message,"Exception occured","fetchSessionDetails Info failed","fetchSessionDetails trigger");

    }
}

export async function fetchExhibitorDetails(accessToken, eventId, exhibitorIds) {
    const apiUrl = `${process.env.REACT_APP_CISCO_EVENTS_API_URL}/api/e/${eventId}/exhibitor/all?${exhibitorIds}`;

    try {
       
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
            return;
        }

        const responseData = await response.json();
        console.log('Exhibitor Details Response:', responseData);
        return responseData;
    } catch (error) {
        console.error('Error fetching exhibitor details:', error);
    }
}
 */


export async function fetchExhibitorDetails(accessToken, tenantId, eventId, exhibitorIds,teaLogServices) {
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/wayfinder/gsx/api/${tenantId}/poi/exhibitor?exhibitorId=${exhibitorIds}`;

    try {
       
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            teaLogServices.teaLogExceptionOccurrence(response.status,response.body,"Not a expected response","Invalid response for Fetching Exhibitor details for "+exhibitorIds,"fetchExhibitorDetails trigger");

            console.error(`HTTP error! status: ${response.status}`);
            return;
        }

        const responseData = await response.json();
        console.log('Exhibitor Details Response:', responseData);

        return responseData.results;
    } catch (error) {
        console.error('Error fetching exhibitor details:', error);
        teaLogServices?.teaLogExceptionOccurrence("400",error.message,"Exception occured","fetchExhibitorDetails Info failed","fetchExhibitorDetails trigger");
    }
}


export async function fetchSessionDetails(accessToken, tenantId, eventId, sessionIds,teaLogServices) {
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/wayfinder/gsx/api/${tenantId}/poi/session?sessionId=${sessionIds}`;

    try {
       
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            teaLogServices.teaLogExceptionOccurrence(response.status,response.body,"Not a expected response","Invalid response for Fetching Session details for "+sessionIds," fetchSessionsDetails trigger");

            console.error(`HTTP error! status: ${response.status}`);
            return;
        }

        const responseData = await response.json();
        console.log('Session Details Response:', responseData);

        return responseData.results;
    } catch (error) {
        console.error('Error fetching exhibitor details:', error);
        teaLogServices?.teaLogExceptionOccurrence("400",error.message,"Exception occured","fetchExhibitorDetails Info failed","fetchExhibitorDetails trigger");
    }
}