// HeaderContainer.js
import React, { useState, useEffect, useRef, act } from "react";
import moment from "moment";
import ClockWidget from './clock-widget'; // Import the ClockWidget component
import axios from "axios";
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { highlightBuildingFloor, manageLayers, highlightSite, removeCurrentPosition, markCurrentPosition } from '../main-container/markCurrentPosition'; // Import the functions
import { listAllAvailableLevels, findCommonLevels } from '../main-container/utilityMethods'; // Import the functions


const HeaderContainer = ({ showCiscoSpacesPopup, timeZoneCity, eventStartDateData, eventEndDateData, handleResetView, eventLogoName, eventName, defaultLevel, defaultBuilding, whereAmILevel, whereAmIBuilding, setBuilding, setLevel, pitch, bearing, lat, long, rotation, eventTimeZone ,  eventTitle="Mandalay Bay", eventSubTitle ="The Hub",defaultBuildingTitle="Melbourne", buildingInfo, defaultActiveTab=0}) => {
  const { map } = useMap();
  const today = new Date();
  const formattedDate = moment(today).format("dddd, MMMM D");
  const [formattedTime, setFormattedTime] = useState(
    new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: eventTimeZone,
    }).format(new Date())
  );
  const [weather, setWeather] = useState(null);
  //const [level, setLevel] = useState("1");
  const [popupVisible, setPopupVisible] = useState(false);
  const [enableLevelSelector, setEnableLevelSelector] = useState(false);


  const [floors, setFloors] = useState([]);
  const [buildingName, setBuildingName] = useState(eventTitle);
  const [levelName, setLevelName] = useState(eventSubTitle);

  const [activeTab, setActiveTab] = useState(defaultActiveTab); // State to track active tab
  const [buildings, setBuildings] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState({}); // Track selected levels per tab

  const scrollContainerRef = useRef(null);


  const iconMapping = {
    'Light rain shower': {
      'day': '/img/weather/light-rain-day.svg',
      'night': '/img/weather/light-rain-night.svg'
    },
    'Cloudy': {
      'day': '/img/weather/cloudy-day.svg',
      'night': '/img/weather/cloudy-night.svg'
    },
    'Partly Cloudy': {
      'day': '/img/weather/partly-cloudy-day.svg',
      'night': '/img/weather/partly-cloudy-night.svg'
    },
    'Sunny': {
      'day': '/img/weather/sunny.svg',
      'night': '/img/weather/night.svg'
    },
    'Overcast': {
      'day': '/img/weather/overcast-day.svg',
      'night': '/img/weather/overcast-night.svg'
    },
    'Mist': {
      'day': '/img/weather/Mist.svg',
      'night': '/img/weather/Mist.svg'
    },
    'Patchy light snow': {
      'day': '/img/weather/patchy-light-snow-day.svg',
      'night': '/img/weather/patchy-light-snow-night.svg'
    },
    'Patchy rain nearby': {
      'day': '/img/weather/patchy-rain-day.svg',
      'night': '/img/weather/patchy-rain-night.svg'
    },
    'Moderate or heavy snow with thunder': {
      'day': '/img/weather/moderate-or-heavy-rain-with-thunder-day.svg',
      'night': '/img/weather/moderate-or-heavy-rain-with-thunder-night.svg'
    },
    'Patchy light snow with thunder': {
      'day': '/img/weather/patchy-light-snow-day.svg',
      'night': '/img/weather/patchy-light-snow-night.svg'
    },
    'Blowing snow': {
      'day': '/img/weather/blowing-snow-day.svg',
      'night': '/img/weather/blowing-snow-night.svg'
    },
    'Blizzard': {
      'day': '/img/weather/blizzard.svg',
      'night': '/img/weather/blizzard.svg'
    },
    'Fog': {
      'day': '/img/weather/fog-day.svg',
      'night': '/img/weather/fog-night.svg'
    },
    'Freezing fog': {
      'day': '/img/weather/freezing-fog-day.svg',
      'night': '/img/weather/freezing-fog-night.svg'
    },
    'Freezing drizzle': {
      'day': '/img/weather/freezing-drizzle-day.svg',
      'night': '/img/weather/freezing-drizzle-night.svg'
    },
    'Heavy freezing drizzle': {
      'day': '/img/weather/heavy-freezing-drizzle-day.svg',
      'night': '/img/weather/heavy-freezing-drizzle-night.svg'
    },
    'Light drizzle': {
      'day': '/img/weather/light-drizzle-day.svg',
      'night': '/img/weather/light-drizzle-night.svg'
    },
    'Patchy light rain': {
      'day': '/img/weather/patchy-light-rain-day.svg',
      'night': '/img/weather/patchy-light-rain-night.svg'
    },
    'Patchy light drizzle': {
      'day': '/img/weather/patchy-light-drizzle-day.svg',
      'night': '/img/weather/patchy-light-drizzle-night.svg'
    },
    'Moderate or heavy freezing rain': {
      'day': '/img/weather/moderate-of-heavy-freezing-rain-day.svg',
      'night': '/img/weather/moderate-of-heavy-freezing-rain-night.svg'
    },
    'Moderate or heavy rain with thunder': {
      'day': '/img/weather/moderate-of-heavy-rain-shower-day.svg',
      'night': '/img/weather/moderate-of-heavy-rain-shower-night.svg'
    },
    'Heavy snow': {
      'day': '/img/weather/heavy-snow-day.svg',
      'night': '/img/weather/heavy-snow-night.svg'
    },
    'Ice pellets': {
      'day': '/img/weather/ice-pellets-day.svg',
      'night': '/img/weather/ice-pellets-night.svg'
    },
    'Light snow': {
      'day': '/img/weather/light-snow-day.svg',
      'night': '/img/weather/light-snow-night.svg'
    },
    'Thundery outbreaks possible': {
      'day': '/img/weather/thundery-outbreaks-day.svg',
      'night': '/img/weather/thundery-outbreaks-night.svg'
    },
    'Torrential rain shower': {
      'day': '/img/weather/torrential-rain-shower-day.svg',
      'night': '/img/weather/torrential-rain-shower-night.svg'
    },
    'Patchy moderate snow': {
      'day': '/img/weather/patchy-moderate-snow-day.svg',
      'night': '/img/weather/patchy-moderate-snow-night.svg'
    },
    'Moderate snow': {
      'day': '/img/weather/moderate-snow-day.svg',
      'night': '/img/weather/moderate-snow-night.svg'
    },
    'Moderate rain': {
      'day': '/img/weather/moderate-rain-day.svg',
      'night': '/img/weather/moderate-rain-night.svg'
    },
    'Patchy heavy snow': {
      'day': '/img/weather/patchy-heavy-snow-day.svg',
      'night': '/img/weather/patchy-heavy-snow-night.svg'
    },
    'Moderate or heavy sleet showers': {
      'day': '/img/weather/moderate-or-heavy-slee-day.svg',
      'night': '/img/weather/moderate-or-heavy-slee-night.svg'
    },
    'Light snow showers': {
      'day': '/img/weather/light-snow-shower-day.svg',
      'night': '/img/weather/light-snow-shower-night.svg'
    },
    'Light sleet showers': {
      'day': '/img/weather/light-sleet-showers-day.svg',
      'night': '/img/weather/light-sleet-showers-night.svg'
    },
    'Light sleet': {
      'day': '/img/weather/light-sleet-day.svg',
      'night': '/img/weather/light-sleet-night.svg'
    },
    'Light showers of ice pellets': {
      'day': '/img/weather/light-shower-of-ice-pellets-day.svg',
      'night': '/img/weather/light-shower-of-ice-pellets-night.svg'
    },
    'Light rain': {
      'day': '/img/weather/light-rain-day.svg',
      'night': '/img/weather/light-rain-night.svg'
    },
    'Heavy rain at times': {
      'day': '/img/weather/heavy-rain-day.svg',
      'night': '/img/weather/heavy-rain-night.svg'
    },
    'Heavy rain': {
      'day': '/img/weather/heavy-rain-day.svg',
      'night': '/img/weather/heavy-rain-night.svg'
    },
    'Patchy snow possible': {
      'day': '/img/weather/patchy-moderate-snow-day.svg',
      'night': '/img/weather/patchy-moderate-snow-night.svg'
    },
    'Moderate or heavy sleet': {
      'day': '/img/weather/moderate-or-heavy-slee-day.svg',
      'night': '/img/weather/moderate-or-heavy-slee-night.svg'
    },
    'Moderate or heavy rain shower': {
      'day': '/img/weather/moderate-of-heavy-rain-shower-day.svg',
      'night': '/img/weather/moderate-of-heavy-rain-shower-night.svg'
    },
    'Moderate or heavy snow showers': {
      'day': '/img/weather/moderate-of-heavy-snow-shower-day.svg',
      'night': '/img/weather/moderate-of-heavy-snow-shower-night.svg'
    },
    'Moderate or heavy showers of ice pellets': {
      'day': '/img/weather/moderate-or-heavy-shower-of-ice-pellets-day.svg',
      'night': '/img/weather/moderate-or-heavy-shower-of-ice-pellets-night.svg'
    },
    'Patchy sleet possible': {
      'day': '/img/weather/patchy-sleet-day.svg',
      'night': '/img/weather/patchy-sleet-night.svg'
    },
    'Patchy freezing drizzle possible': {
      'day': '/img/weather/patchy-feeling-drizzle-day.svg',
      'night': '/img/weather/patchy-feeling-drizzle-night.svg'
    },
    'Moderate rain at times': {
      'day': '/img/weather/moderate-rain-day.svg',
      'night': '/img/weather/moderate-rain-night.svg'
    },
    'Light freezing rain': {
      'day': '/img/weather/light-freezing-rain-day.svg',
      'night': '/img/weather/light-freezing-rain-night.svg'
    },
    'Patchy light rain with thunder': {
      'day': '/img/weather/light-rain-with-thunder-day.svg',
      'night': '/img/weather/light-rain-with-thunder-night.svg'
    }
  };



  const fetchFloors = (map) => {
    if (map) {
      let levels = null;
      levels = window.PointrWebSDK.MapWidget.getInstance().getUiController().getCurrentBuilding().levels;
      setFloors(levels?.map(item => String(item.levelIndex)));
    }
  };


  const baseDate = new Date(eventStartDateData.year, eventStartDateData.month, eventStartDateData.day);
  const endDate = new Date(eventEndDateData.year, eventEndDateData.month, eventEndDateData.day);

  const currentDate = new Date();
  var dayDifference = currentDate < baseDate ? 1 : Math.floor((currentDate - baseDate) / (1000 * 60 * 60 * 24)) + 1;

  dayDifference = Math.min(Math.floor((endDate - baseDate) / (1000 * 60 * 60 * 24)) + 1, dayDifference);

  useEffect(() => {
    if (!popupVisible) {
      setBuildingNameAndFloorNames();
    }
  }, [popupVisible]); // Re-run this effect when popupVisible changes


  useEffect(() => {
    if (buildings?.length > 0) {
      const building = buildings?.find(building => building.buildingInternalIdentifier === defaultBuilding)
      const buildingIndex = buildings?.findIndex(
        building => building.buildingInternalIdentifier === defaultBuilding
      );
      const levelIndex = building?.levels?.find(level => level.levelIndex === defaultLevel)
      setSelectedLevels(({
        [activeTab]: levelIndex, // Store selected level per building (tab)
      }));
      setActiveTab(buildingIndex);
      if (building)
        selectLevel(defaultBuilding, defaultLevel, building, building?.levels?.find(level => level.levelIndex === defaultLevel));
    }
  }, [buildings]); // Re-run this effect when popupVisible changes


  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  const selectLevel = (buildingIndex, levelIndex, building, level) => {
    setSelectedLevels(({
      [activeTab]: levelIndex, // Store selected level per building (tab)
    }));
    setBuilding(buildingIndex);
    defaultBuilding = buildingIndex;
    const newLevel = parseInt(level.levelIndex);
    setLevel(newLevel);
    defaultLevel = newLevel;
    setBuildingName(building.buildingTitle);
   // setLevelName(level.levelLongTitle);
    setPopupVisible(false);
    if (building.buildingTitle === defaultBuildingTitle) {
      manageLayers(map?.getUiController().getMapViewController().getView(), null, newLevel)
      handleResetView(false);
    } else {
      manageLayers(map?.getUiController().getMapViewController().getView(), null, newLevel)
    }
    if (whereAmIBuilding !== building || whereAmILevel !== level) {
      removeCurrentPosition(map.getUiController().getMapViewController().getView());
      if (building.buildingTitle === defaultBuildingTitle)
        manageLayers(map?.getUiController().getMapViewController().getView(), null, levelIndex)
      return;
    } else {
      markCurrentPosition(map, long, lat, rotation, true, pitch, bearing, whereAmIBuilding, whereAmILevel, whereAmIBuilding, whereAmILevel);
    }
    // highlightBuildingFloor(map, buildings, buidling.buildingInternalIdentifier, newLevel);
    /*if (map) {
      mapView.map.flyTo({ center: [-115.1782890405586,36.088308032921276], zoom: 22.5, pitch: 65, bearing: 16 });
    }*/

  };

  // Update weather data when fetched from WeatherWidget
  const fetchWeatherData = async () => {
    try {
      const response = await axios.get(
        `https://api.weatherapi.com/v1/current.json?key=f4a4bff595ab48b092571334242208&q=` + timeZoneCity
      );
      setWeather(response.data);
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };

  const setBuildingsAndFloors = () => {
    try{
      var fetchedBuildings = [];
      if (buildingInfo) {
        fetchedBuildings = buildingInfo;
      } else {
        fetchedBuildings = map?.siteBuildingManager?.getAllBuildings();
      }
    if (fetchedBuildings?.length > 1) {
      // Add the new "Melbourne" building to the fetchedBuildings array
      const clonedBuilding = {
        ...fetchedBuildings[0], // Clone the first building (or another building of your choice)
        buildingTitle: defaultBuildingTitle, // Change the building title to "Melbourne"
        levels: listAllAvailableLevels(fetchedBuildings)
      };

      //setEnableLevelSelector(true)

      // Add the cloned "Melbourne" building to the fetchedBuildings array
      fetchedBuildings = [
        // ...fetchedBuildings, // Spread the original fetched buildings
        clonedBuilding // Add the new cloned building with the title "Melbourne"
      ];

      if (buildings.length === 0)
        setBuildings(fetchedBuildings); // Use setState to update the buildings data

    } else {
      setEnableLevelSelector(false)
    }

    setBuildingNameAndFloorNames();
  } catch (error) {
    console.warn("An error occurred while processing in setBuildingsAndFloors:", error);
}

  };

  const setBuildingNameAndFloorNames = () => {
    if (buildings && buildings[activeTab] && buildings[activeTab]?.levels[0]) {
      setBuildingName(buildings[activeTab]?.buildingTitle);
      if (buildings[0]?.buildingTitle === "Mandalay Bay" && "Level 1" === buildings[0]?.levels[0]?.levelLongTitle) {
        setLevelName("The Hub")
      } else {
       // setLevelName(buildings[activeTab]?.levels?.find(level => level.levelIndex === defaultLevel)?.levelLongTitle);
      }
    }
  }

  useEffect(() => {
    fetchWeatherData();
    setBuildingsAndFloors();
    const intervalId = setInterval(() => {
      fetchWeatherData();

      // Fetch weather data every 5 minutes
    }, 5 * 60 * 1000); // 5 minutes interval in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let scrollAmount = 0;
    let direction = 'down'; // Initial scroll direction
    let scrollInterval;
    let scrollTimeout;


    const scroll = () => {
      if (scrollContainer) {

        const containerHeight = scrollContainer.clientHeight;
        const scrollHeight = scrollContainer.scrollHeight;
        scrollAmount = containerHeight;
        if (direction === 'down') {
          scrollContainer.scrollBy({
            top: scrollAmount,
            behavior: 'smooth'
          });

          if (scrollContainer.scrollTop + containerHeight >= scrollHeight - scrollAmount) {
            direction = 'up';
          }
        } else if (direction === 'up') {
          scrollContainer.scrollBy({
            top: -scrollAmount,
            behavior: 'smooth'
          });

          if (scrollContainer.scrollTop <= scrollAmount) {
            direction = 'down';
          }
        }
      }
    };

    const startScrolling = () => {
      scrollInterval = setInterval(scroll, 5000); // Adjust interval as needed
      scrollTimeout = setTimeout(scroll, 5000);
      scroll(); // Initial scroll immediately
    };

    const stopScrolling = () => {
      clearInterval(scrollInterval);
      clearTimeout(scrollTimeout);
    };

    startScrolling();

    // Cleanup on component unmount
    return () => {
      stopScrolling();
    };
  }, []);

  useEffect(() => {
    if (popupVisible) {
      // Check if the active building title is "Melbourne" and it has levels
      if (buildings[activeTab]?.buildingTitle === "Melbourne" && buildings[activeTab]?.levels?.length > 0) {

        // Automatically select the first level (index 0)
        if (popupVisible) {
          setActiveTab(activeTab);
          selectLevel(buildings[activeTab]?.buildingInternalIdentifier, buildings[activeTab].levels[0].levelIndex, buildings[activeTab], buildings[activeTab].levels[0]);

        }
      }
    } else {
      setBuildingNameAndFloorNames();
    }
  }, [activeTab]);

  return (
    <div className="header-container">
      <div className="header-container-card-1">
        <div className="header-container-card-1-left">
          <div className="header-container-card-1-left-logo" id="header-container-card-1-left-logo" style={{
            backgroundImage: `url('/img/${eventLogoName}')`,
          }} />
        </div>
        <div className="header-container-card-1-seperator"></div>
        <div className="header-container-card-1-right">
          <div className="header-container-card-1-right-inner">
            <div className="header-container-card-1-right-inner-location">
              {buildingName}
            </div>
            <div className="header-container-card-1-right-inner-level">{levelName}</div>
          </div>
          {enableLevelSelector &&
           (<button className="header-container-card-1-right-dropdown-toggle" onClick={togglePopup}>
            <svg width="1.25vw" height="0.625vw" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0002 9.33315C9.6962 9.33315 9.39354 9.23048 9.14687 9.02382L1.14687 2.35715C0.581538 1.88648 0.504204 1.04515 0.976204 0.479816C1.44687 -0.0855178 2.28687 -0.161518 2.85354 0.309149L10.0149 6.27715L17.1642 0.523816C17.7375 0.0624822 18.5775 0.153149 19.0389 0.726482C19.5002 1.29982 19.4095 2.13848 18.8362 2.60115L10.8362 9.03848C10.5922 9.23448 10.2962 9.33315 10.0002 9.33315Z"
                fill="white"
              ></path>
            </svg>
          </button>)}
        </div>
      </div>
      {popupVisible && (
        <div className="overlay" onClick={togglePopup}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={togglePopup}>
              ×
            </button>
            {/* Tab Navigation */}
            <div className="building-tab-navigation">
              {buildings?.map((building, index) => (
                <button
                  key={index}
                  className={`building-tab-button ${activeTab === index ? "active" : ""}`}
                  onClick={() => setActiveTab(index)}
                >
                  {building?.buildingTitle}
                </button>
              ))}
            </div>
            <div className="popup-heading">Building: {buildings[activeTab]?.buildingTitle}</div>
            <div className="popup-subheading">Select a Floor</div>
            <div className="popup-content">
              {buildings[activeTab]?.levels.map((lvl, index) => (
                <div
                  key={index}
                  className={`popup-circle ${selectedLevels[activeTab] === lvl.levelIndex ? "selected" : ""
                    }`} // Apply selected class based on active tab
                  onClick={() => selectLevel(buildings[activeTab]?.buildingInternalIdentifier, lvl.levelIndex, buildings[activeTab], lvl)}
                >
                  {lvl.levelIndex}
                  {selectedLevels[activeTab] === lvl.levelIndex && (
                    <div className="popup-circle selected-you-are-here">You are here</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="header-container-card-2">
        <div className="header-container-card-2-left">
          <div className="header-container-card-2-left-day">
            <div className="header-container-card-2-left-day-counter">Day {dayDifference}</div>
            <div className="header-container-card-2-left-day-date">{formattedDate}</div>
          </div>
          <div className="header-container-card-2-left-time-container">
            <div className="header-container-card-2-left-time-container-counter">#####</div>
            <div className="header-container-card-2-left-time-container-time">
              <div className="circle" />
              <span className="time-span">{formattedTime}</span> {/* Use formattedTime here */}
            </div>
          </div>
        </div>
        <ClockWidget onTimeUpdate={setFormattedTime} eventTimeZone={eventTimeZone} /> {/* Add ClockWidget component */}
        <div className="header-container-card-2-seperator"></div>
        <div className="header-container-card-2-right">
          {weather ? (
            <>
              <div className="header-container-card-2-right-weather-icon">
                <img src={iconMapping[weather.current.condition.text] ? ((weather.current.is_day === 1) ? (iconMapping[weather.current.condition.text].day) : (iconMapping[weather.current.condition.text].night)) : ('/img/weather/Cloud.svg')} alt={weather.current.condition.text} className="header-container-card-2-right-weather-icon" />
              </div>
              <div className="header-container-card-2-right-location">
                <div className="header-container-card-2-right-location-name">
                  {weather.location.name}
                </div>
                <div className="header-container-card-2-right-location-temperature">
                  {eventName === "CLAPJC" || eventName === "CLEMEA" ? `${weather.current.temp_c}°C` : `${weather.current.temp_f}°F`}
                </div>
                <div className="header-container-card-2-right-location-weather-info">
                  {weather.current.condition.text}
                </div>
              </div>
            </>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
      <div className="header-container-card-3"></div>
      <div className="header-container-card-4" ref={scrollContainerRef} onClick={showCiscoSpacesPopup}>
        <div className="header-container-card-4-left">
          <div className="header-container-card-4-left-title">Powered by:</div>
          <div className="header-container-card-4-left-logo"></div>
        </div>
        <div className="header-container-card-4-scroll-bottom">
          <div className="header-container-card-4-scroll-bottom-title"> <span>Tap to</span>
            <br />
            <span>learn more</span></div>

        </div>
      </div>
    </div>
  );
};

export default HeaderContainer;
