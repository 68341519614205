import React, { useEffect, useState } from "react";
import { updateLayerFilterForMultipleFeature, resetBoothLayerFilter } from '../main-container/layerUtils';
import HubTalkTheaters from '../../../../assets/images/facilities/hub-talk-theaters.svg';
import Cafeteria from '../../../../assets/images/facilities/cafes.svg';
import Lounges from '../../../../assets/images/facilities/lounges.svg';
import Restrooms from '../../../../assets/images/facilities/rest-rooms.svg';
import Registrations from '../../../../assets/images/facilities/registration.svg';
import CiscoStore from '../../../../assets/images/facilities/cisco-store.svg';
import ProfessionalPhotos from '../../../../assets/images/facilities/professional-photos.svg';
import EntryExit from '../../../../assets/images/facilities/entry-exit.svg';
import ciscoAILogo from '../../../../assets/images/ai/ai-logo.svg';
import AI from '../../../../assets/images/facilities/ai.svg';
import Gamification from '../../../../assets/images/facilities/gamification.svg';
import Lunch from '../../../../assets/images/facilities/lunch.svg';

import AIRecommendationsContainer from "./ai-recommendations";


const RightContainer = ({ hubwalksData, facilitiesData, pitch, bearing, centerLat, centerLong, visibleAIContainer,setShowPopupCard ,showAICard,setShowAICard, showPopup,eventId, tenantId, recommendationsInfo,setRecommendationsInfo,attendeeUserToken ,attendeeInfo,constraintsLookUp,teaLogService, setShowFaqList , showFaqList , setTriggerDirectionsPopup,triggerDirectionsPopup, setPopupState, showAiLoader}) => {
  const [hubwalksInfo, setHubwalksInfo] = useState([]);
  const [clickedCardIndex, setClickedCardIndex] = useState(null);
  const [clickedCardTitle, setClickedCardTitle] = useState(null);

  const [clickedFacilityCardIndex, setClickedFacilityCardIndex] = useState(null);
  const [clickedFacilityCardTitle, setClickedFacilityCardTitle] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const [facilityData, setFacilityData] = useState({
    cafes: [],
    lounges: [],
    registration: [],
    theatre: [],
    restrooms: [],
    toilets: [],
    ciscostore: [],
    landmarkAttraction: [],
    professionalphotos: [],
    entryexit: [],
    seating: [],
    gamification: [],
    ai:[],
    lunch: []
  });
  const iconMapping = {
    CiscoStore: CiscoStore,
    HubTalkTheaters: HubTalkTheaters,
    Cafeteria: Cafeteria,
    Lunch: Lunch,
    Lounges: Lounges,
    Restrooms: Restrooms,
    Toilets: Restrooms,
    Registrations: Registrations,
    ProfessionalPhotos: ProfessionalPhotos,
    EntryExit: EntryExit,
    Keynote: HubTalkTheaters,
    Gamification:Gamification,
    AI: AI
  };

  const facilitiesCardData = {
    //  const helpDeskPois = Object.values(pois).filter((poi) => poi.properties.typeCode === "help-desk")
    "title": "Facilities",
    "sub-title": "Select to find on map:",
    "cards": [
      { "title": "Registrations", "icon": "Registrations" },
      { "title": "Hub Talk Theaters", "icon": "HubTalkTheaters" },
      { "title": "Cisco Store", "icon": "CiscoStore" },
      { "title": "Professional Photos", "icon": "ProfessionalPhotos" },
      { "title": "Lounges", "icon": "Lounges" },
      { "title": "Gamification", "icon": "Gamification" },
      { "title": "AI", "icon": "AI" },
      { "title": "Cafes", "icon": "Cafeteria" },
      { "title": "Lunch", "icon": "Lunch" },
      { "title": "Restrooms", "icon": "Restrooms" },
      { "title": "Toilets", "icon": "Restrooms" },
      { "title": "Entry & Exit", "icon": "EntryExit" },
      { "title": "Keynote", "icon": "HubTalkTheaters" }
    ]
  };

  const facilityNameMapping = {
    'Cisco Store': ["ciscostore", "CiscoStore"],
    'Professional Photos': ["professionalphotos", "ProfessionalPhotos"],
    'Entry & Exit': ["entryexit", "EntryExit"],
    'Gamification': ["gamification", "gamification"],
    'AI': ["ai", "sponsor-booth"],
    'Cafes': ["cafes", "Cafe-normal"],
    'Lunch': ["lunch", "CafeCoffeeNew"],
    'Hub Talk Theaters': ["theatre", "HubTheater"],
    'Keynote': ["seating", "HubTheater"],
    'Lounges': ["lounges", "Lounge"],
    'Restrooms': ["restrooms", "Restroom-n"],
    'Toilets': ["toilets", "Restroom-n"],
    'Registrations': ["registration", "Registration"],
    
  };

  // Function to handle button click
  const handleBadgeScan = () => {
    setShowPopupCard(true); // Show the card
  };

  useEffect(() => {
    if (!hubwalksData || !hubwalksData.hubwalksData)
      return;

    hubwalksData = hubwalksData.hubwalksData;
    const convertedHubs = Object.keys(hubwalksData).map((key) => {
      const hub = hubwalksData[key];

      return {
        title: hub.industryName || '',
        subtitle: hub.name || '',
        icon: 'icon 1',
        color: '#FEAF1D'
      };
    });

    // Update the hubWalksCardData with the new cards
    const updatedHubWalksCardData = {
      index: 1,
      title: "Hub Walks",
      'sub-title': "Find audio guided tours",
      cards: convertedHubs
    };

    setHubwalksInfo(updatedHubWalksCardData);

  }, []);

  const handleHubwalkClick = async (index, title, subtitle) => {
    setClickedCardIndex(index === clickedCardIndex ? null : index);
    setClickedCardTitle(title === clickedCardTitle ? null : title);
    const pointrWeb = window.PointrWebSDK.MapWidget.getInstance();
    const uiController = pointrWeb.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();

    if (index === clickedCardIndex) {
      pointrWeb.unhighlight();
      mapViewController.unhighlightAllPois();
      pointrWeb.zoomTo(18);
      mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');
      mapView.setLayoutProperty("rmaps-symbol_selected_ptr", "icon-allow-overlap", false);
      mapView.setLayoutProperty("rmaps-symbol_selected_ptr", "text-allow-overlap", false);

      resetBoothLayerFilter(mapView);
      return;
    }
    if (!hubwalksData || !hubwalksData.hubwalksData)
      return;

    pointrWeb.unhighlight();
    hubwalksData = hubwalksData.hubwalksData;

    const hubInfo = Object.keys(hubwalksData)
      .filter((key) => {
        const hub = hubwalksData[key];
        return subtitle === hub.name;
      })
      .map((key) => {
        const hub = hubwalksData[key];

        return { hub };

      });

    if (!hubInfo || hubInfo.length === 0)
      return;

    const hubs = hubInfo[0].hub.hubs;
    let fids = [];
    for (let i = 0; i < hubs.length; i++) {
      const h = hubs[i];
      fids.push(h.properties.fid);
    }
    let layer = mapView.getLayer("rmaps-booth-marker")
    const highlightLayer = mapView.getLayer("rmaps-symbol_selected_ptr");

    if (highlightLayer) {
      // mapView.removeLayer("rmaps-symbol_selected_ptr");
      // highlightLayer.layout["icon-image"] = 'Hubwalk';
      mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'Hubwalk');
      mapView.setLayoutProperty("rmaps-symbol_selected_ptr", "icon-allow-overlap", true);
      mapView.setLayoutProperty("rmaps-symbol_selected_ptr", "text-allow-overlap", true);



      // updateLayerFilterForMultipleFeature(mapView, hubs);
      // mapView.addLayer(highlightLayer,"rmaps-symbol_selected_ptr");
    }

    for (let i = 0; i < hubs.length; i++) {
      const fid = hubs[i].properties.fid;

      let valueAfterTilde;

      if (fid.includes('~')) {
        valueAfterTilde = fid.split('~')[1];
      } else {
        valueAfterTilde = fid;
      }

      try {
        const poi = await pointrWeb.getPoiManager().get(valueAfterTilde);
        // highlightLayer.layout.visibility = "visible";
        pointrWeb.highlight(poi);
        pointrWeb.hidePoiDetails(poi);
        mapView.map.flyTo({
          center: [-115.17791907047695, 36.087886474666035
          ],
          zoom: 17, pitch: pitch, bearing: bearing, speed: 0.2
        });
        // mapView.flyTo(calculateMidPoint(feature.coordinates), 20.5);
      } catch (error) {
        console.error("Error getting or highlighting POI:", error);
      }
    }


    //layer.layout.icon-image === "Hubwalk";

    //  mapViewController.highlightPoi(poi);
    //  pointrWeb.hidePoiDetails(poi);
  };

  const handleFacilitiesClick = async (index, title) => {
    setClickedFacilityCardIndex(index === clickedFacilityCardIndex ? null : index);
    setClickedFacilityCardTitle(title === clickedFacilityCardTitle ? null : title);
    const pointrWeb = window.PointrWebSDK.MapWidget.getInstance();
    const uiController = pointrWeb.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();

    if (index === clickedFacilityCardIndex) {


      Object.keys(facilityNameMapping).forEach(title => {
        const facilityId = facilityNameMapping[title][0];
        mapView.setLayoutProperty(`rmaps-${facilityId}-marker-highlight`, 'visibility', 'none');
        mapView.setLayoutProperty(`rmaps-${facilityId}-fill-highlight`, 'visibility', 'none');
        mapView.setLayoutProperty(`rmaps-${facilityId}-marker`, 'visibility', 'visible');
        mapView.setLayoutProperty(`rmaps-${facilityId}-covers`, 'visibility', 'visible');

      });
      pointrWeb.unhighlight();
      mapViewController.unhighlightAllPois();
      if (centerLat && centerLong) {
        mapView?.map.flyTo({
          center: [centerLong, centerLat],
          zoom: 17.1,
          pitch: pitch, bearing: bearing, speed: 0.4
        });
      } else {
        pointrWeb.zoomTo(18);
      }

      mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');
      return;
    }
    if (!facilityData)
      return;

    pointrWeb.unhighlight();

    const facilitiesInfo = facilityData[facilityNameMapping[title][0]];


    if (!facilitiesInfo || facilitiesInfo.length === 0)
      return;


    const highlightLayer = mapView.getLayer("rmaps-symbol_selected_ptr");

    console.log(highlightLayer);
    if (highlightLayer) {
      // mapView.removeLayer("rmaps-symbol_selected_ptr");
      // highlightLayer.layout["icon-image"] = 'Hubwalk';
      // mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', facilityNameMapping[title][1]);
      // updateLayerFilterForMultipleFeature(mapView, facilitiesInfo);
      // mapView.addLayer(highlightLayer,"rmaps-symbol_selected_ptr");
    }

    for (let i = 0; i < facilitiesInfo.length; i++) {
      const fid = facilitiesInfo[i].properties.fid;
      facilitiesInfo[i].properties.highlight = true;
      Object.keys(facilityNameMapping).forEach(title => {
        const facilityId = facilityNameMapping[title][0];
        mapView.setLayoutProperty(`rmaps-${facilityId}-marker-highlight`, 'visibility', 'none');
        mapView.setLayoutProperty(`rmaps-${facilityId}-fill-highlight`, 'visibility', 'none');
        mapView.setLayoutProperty(`rmaps-${facilityId}-marker`, 'visibility', 'visible');
        mapView.setLayoutProperty(`rmaps-${facilityId}-covers`, 'visibility', 'visible');
      });

      mapView.setLayoutProperty(`rmaps-${facilityNameMapping[title][0]}-marker-highlight`, 'visibility', 'visible');
      mapView.setLayoutProperty(`rmaps-${facilityNameMapping[title][0]}-fill-highlight`, 'visibility', 'visible');
      mapView.setLayoutProperty(`rmaps-${facilityNameMapping[title][0]}-marker`, 'visibility', 'none');
      mapView.setLayoutProperty(`rmaps-${facilityNameMapping[title][0]}-covers`, 'visibility', 'none');


      let valueAfterTilde;

      if (fid.includes('~')) {
        valueAfterTilde = fid.split('~')[1];
      } else {
        valueAfterTilde = fid;
      }

      try {
        const poi = await pointrWeb.getPoiManager().get(valueAfterTilde);
        // highlightLayer.layout.visibility = "visible";
        //  mapViewController.highlightPoi(poi);
        // pointrWeb.hidePoiDetails(poi);

        // mapView.flyTo(calculateMidPoint(feature.coordinates), 20.5);
        if (centerLat && centerLong) {
          mapView?.map.flyTo({
            center: [centerLong, centerLat],
            zoom: 17.1,
            pitch: pitch, bearing: bearing, speed: 0.4
          });
        } else {
          pointrWeb.zoomTo(18);
        }
      } catch (error) {
        console.error("Error getting or highlighting POI:", error);
      }
    }


    //layer.layout.icon-image === "Hubwalk";

    //  mapViewController.highlightPoi(poi);
    //  pointrWeb.hidePoiDetails(poi);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if(!document.getElementById("icon-grid")){
        console.log("Facility data missing, re-fetching...");
       setReloadTrigger(prev => prev + 1);
      }
    }, 10000); 

    return () => clearInterval(interval);
  }, [facilityData]);


  useEffect(() => {
    let timeoutId;
    if (clickedCardIndex !== null) {
      timeoutId = setTimeout(() => {
        handleHubwalkClick(clickedCardIndex, clickedCardTitle);
        setClickedCardIndex(null);
      }, 15000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [clickedCardIndex]);

  useEffect(() => {
    let timeoutId;
    if (clickedFacilityCardIndex !== null) {
      timeoutId = setTimeout(() => {
        handleFacilitiesClick(clickedFacilityCardIndex, clickedFacilityCardTitle);
        setClickedFacilityCardIndex(null);
      }, 15000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [clickedFacilityCardIndex]);

  useEffect(() => {
    // Fetch data from the API only once when the component mounts
    const getFacilities = async () => {
      try {
        const pointrWeb = window.PointrWebSDK.MapWidget.getInstance();
        let pois = null;
        const maxRetries = 30; // Number of retries
        const retryDelay = 1000; // Delay in milliseconds (1 second)

        // Function to wait for POIs to be defined
        const waitForPois = async () => {
          for (let i = 0; i < maxRetries; i++) {
            pois = await pointrWeb?.getPoiManager()?.getPoisBySite(pointrWeb.getCurrentSite()?.siteInternalIdentifier);
            if (pois) {
              return pois;
            }
            await new Promise(resolve => setTimeout(resolve, retryDelay));
          }
          return null;
        };

        // Wait until pois is defined or maxRetries reached
        pois = await waitForPois();

        console.log(pois);
        console.log(facilitiesData);
        if (!pois)
          return;
        if (!facilitiesData)
          return;
        // Create a Set of all typeCodes from facilitiesData
        const typeCodeSet = new Set();
        const title = new Set();

        facilitiesData.forEach(facility => {
          title.add(facility.title);
          facility.typeCodes.forEach(code => {
            typeCodeSet.add(code);
          });
        });


        const categorizedData = {
          cafes: [],
          lounges: [],
          registration: [],
          theatre: [],
          restrooms: [],
          toilets: [],
          ciscostore: [],
          landmarkAttraction: [],
          professionalphotos: [],
          entryexit: [],
          seating: [],
          gamification:[],
          ai:[],
          lunch: [],
        };
        Object.values(pois).forEach(poi => {
          const { typeCode } = poi.properties;
          if (typeCodeSet.has(typeCode)) {
            switch (typeCode) {
              case "cafes-coffee-tea-houses":
                categorizedData.cafes.push(poi);
                break;
              case "food-beverage":
              case "food-drink-stall":
                categorizedData.lunch.push(poi);
                break;
              case "lounge":
                categorizedData.lounges.push(poi);
                break;
              case "registration":
                categorizedData.registration.push(poi);
                break;
              case "theatre":
                categorizedData.theatre.push(poi);
                break;
              case "toilets":
              case "accessible-restroom":
                if (title.has("Toilets")) {
                  categorizedData.toilets.push(poi);
                } else {
                  categorizedData.restrooms.push(poi);
                }

                break;
              case "department-store":
                categorizedData.ciscostore.push(poi);
                break;
              case "work-space":
                categorizedData.professionalphotos.push(poi);
                break;
              case "entrance-exit":
                categorizedData.entryexit.push(poi);
                break;
              case "landmark-attraction":
                categorizedData.landmarkAttraction.push(poi);
                break;
              case "seating":
                categorizedData.seating.push(poi);
                break;
              case "arcade-game-room":
                categorizedData.gamification.push(poi);
                break;
              case "sponsor-booth":
                categorizedData.ai.push(poi);
                break;  
              default:
                break;
            }
          }
        });
        setFacilityData(categorizedData);
        console.log("categorizedData");
        console.log(categorizedData);
      } catch (err) {
        console.log(err);
      }
    };

    getFacilities();
  }, []);
  /*
  const hubWalksCardData = {
    "index": 1,
    "title": "Hub Walks",
    "sub-title": "Find audio guided tours",
    "cards": [
      { "title": "Industries #1", "subtitle": "Manufacturing", "icon": "icon 1", "color": "#FEAF1D", },
      { "title": "Industries #2", "subtitle": "Government", "icon": "icon 1", "color": "#64B53C", },
      { "title": "Industries #3", "subtitle": "FSI", "icon": "icon 1", "color": "#CF3559", },
      { "title": "Industries #4", "subtitle": "Splunk for Cisco Sellers", "icon": "icon 1", "color": "#4F2A87", },
      { "title": "Industries #5", "subtitle": "Purpose", "icon": "icon 1", "color": "#A02788", },
      { "title": "Industries #6", "subtitle": "Cisco for Splunk Sellers", "icon": "icon 1", "color": "#FEAF1D", }
    ]
  };
*/



  return (
    showAICard? 
      (<AIRecommendationsContainer 
        showPopup={showPopup} 
        setShowAICard={setShowAICard} 
        eventId={eventId}
        tenantId={tenantId}
        recommendationsInfo={recommendationsInfo} 
        setRecommendationsInfo={setRecommendationsInfo} 
        attendeeUserToken={attendeeUserToken}
        attendeeInfo={attendeeInfo}
        constraintsLookUp={constraintsLookUp}
        teaLogService={teaLogService}
        setShowFaqList={setShowFaqList}
        showFaqList={showFaqList}
        setTriggerDirectionsPopup={setTriggerDirectionsPopup}
        triggerDirectionsPopup={triggerDirectionsPopup}
        setPopupState={setPopupState}
        showAiLoader={showAiLoader}/>
      ) :(
    <div className="right-container-wrapper">
    {visibleAIContainer ? (
      <div className="ai-container">
        <div className="ai-container-section">
          <div className="ai-container-section-header">
            Get Personalized Recommendations
          </div>
          </div>
          <img src={ciscoAILogo} class="ai-container-logo" />
          
          <div className="ai-container-scan-your-badge-container">
         
          <button className="ai-container-scan-your-badge-container-button" onClick={handleBadgeScan} >
          Launch Cisco AI Assistant​
          </button>
          </div>
      </div>) : (
      <div className="container">
        <div key={hubwalksInfo} className="section">
          <div className="section-header">
            <h1>{hubwalksInfo.title}</h1>
            <h2>{hubwalksInfo['sub-title']}</h2>
          </div>
          <div className="cards-container">
            {hubwalksInfo?.cards?.map((card, index) => (
              <div key={index} className={`card ${index === clickedCardIndex ? 'card-clicked' : ''}`}
                onClick={() => handleHubwalkClick(index, card.title, card.subtitle)}>
                <div className="right-container-item-left">

                  <div
                    className="circle-badge-right-container"
                    style={{ backgroundColor: card.color }}
                  >
                    <div className="icon-hub-walks" ></div>
                  </div>

                </div>
                <div className="title-wrapper">
                  <div className="title">{card.title}</div>
                  <div className="sub-title">
                    {card.subtitle}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>)
    }
    <div className="container-2">
      <div key={facilitiesCardData} className="section">
        <div className="section-header">
          <h1>{facilitiesCardData.title}</h1>
          <h2>{facilitiesCardData['sub-title']}</h2>
        </div>
        <div className="cards-container-grid" key={reloadTrigger}>
          {facilitiesCardData?.cards?.map((card, index) => (
            facilityData[facilityNameMapping[card.title][0]].length > 0 ? (
              <div key={index} className="card-grid-wrapper" id="icon-grid">
                <div
                  id={`facilities-${card.icon.toLowerCase()}`}
                  onClick={() => handleFacilitiesClick(index, card.title)}
                  className={`card-grid ${index === clickedFacilityCardIndex ? 'facility-card-clicked' : ''}`}
                >
                  <div className="icon-grid" >
                    <img src={iconMapping[card.icon]} alt={card.title} className="button-icon-grid" />
                  </div>
                </div>
                <div className="title-grid">{card.title}</div>
              </div>
            ) : null // If the condition is false, return null (or some fallback content)
          ))}
        </div>
      </div>
    </div>
  </div>)
  )
};

export default RightContainer;
