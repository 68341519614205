import React, { useEffect, useState, useRef } from "react";
import MapComponent from "../common-pointr-map-container/GenericPointrMapContainer"
import LeftContainer from "../left-container/left-container";
import HeaderContainer from "../header-container/header-container";
import RightContainer from "../right-container/right-container";
import SearchPopup from '../main-container/SearchPopup'; // Import SearchPopup component
import FooterContainer from '../footer-container/footer-container';
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { handleReset } from '../main-container/handleResetView'; // Import the functions
import { calculateMidPoint, manageLayers,markCurrentPosition, removeCurrentPosition } from '../main-container/markCurrentPosition'; // Import the functions
import { fetchGsxData } from '../left-container/fetchZonesHubwalksInfo';
import CustomPopup from '../main-container/DirectionPopup';
import ciscoSpacesPopupIcon from '../../../../assets/images/powered-by-spaces-cl-emea.png';
import Screensaver from "../countdown-timer-container/screensaver";
import ciscoSpacesPopupImage from '../../../../assets/images/cisco-space-popup-cl-emea.png';
import {handleWhereAmI} from '../footer-container/handle-where-am-i';  
import ciscoAILogo from '../../../../assets/images/ai/ai-logo.svg';
import ciscoAIQrPlaceHolderLogo from '../../../../assets/images/ai/qr-code-place-holder.svg';
import ciscoAIQrPlaceHolderFailedLogo from '../../../../assets/images/ai/qr-code-place-holder-failed.svg';

import ciscoClosebutton from '../../../../assets/images/close-button.svg';
import ciscoHelpbutton from '../../../../assets/images/ai/help-icon.svg';
import CiscoAiAuth from '../right-container/ciscoAiAuth';
import {fetchAttendeeInfo, fetchRecommendedEvents} from '../right-container/ciscoAiApis';
import { TeaLogService,TeaLogModule } from '@events/tealium';
import {initializeXAPIScanner, getHashes} from '../qr-code-scanner-component/qr-code-scanner';
import AIFAQListContainer from "../right-container/ai-faq-list";
import {sendTrackingEventForCiscoAI} from "../main-container/pendoApis"
const CiscoLiveEMEAMainContainer = () => {
  const kioskData = {
    "1": {
      "lat": 52.342729817156794,
      "long": 4.88983559062865,
      "rotation": 1.5,
      "bearing": -83.8,
      "building": 8,
      "level":0
    },
    "2": {
      "lat":52.34304739930653,
      "long": 4.889270989661952,
      "rotation": 1.5,
      "bearing": -83.5,
      "building": 8,
      "level":0
    },
    "3": {
      "lat": 52.34291789711398,
      "long": 4.889002347549763,
      "rotation": -0.1,
      "bearing": 7.7,
      "building": 8,
      "level": 0
    },
    "4": {
      "lat": 52.342938641773316,
      "long": 4.88863391263547,
      "rotation": -0.1,
      "bearing": 7.3,
      "building": 8,
      "level": 0
    },
    "5": {
      "lat": 52.34270049580422,
      "long": 4.888281210174,
      "rotation": -2.4,
      "bearing": 138.8,
      "building": 8,
      "level": 0
    },
    "6": {
      "lat": 52.3417931366011,
      "long": 4.889853488005201,
      "rotation": 3,
      "bearing": -173,
      "building": 8,
      "level": 0
    },
    "7": {
      "lat": 52.3421907282972,
      "long": 4.889933315632021,
      "rotation": 1.5,
      "bearing": -85.19,
      "building": 8,
      "level": 0
    },
    "8": {
      "lat": 52.34218811829402,
      "long": 4.889891267993676,
      "rotation": -1.6,
      "bearing": 99.5,
      "building": 8,
      "level": 0
    },
    "9": {
      "lat": 52.34247223116844,
      "long": 4.889034164062082,
      "rotation": -0.1,
      "bearing": 7.6,
      "building": 8,
      "level": 0
    },
    "10": {
      "lat": 52.34246884041784,
      "long": 4.887584477600029,
      "rotation": 1.4,
      "bearing": -82.7,
      "building": 8,
      "level": 0
    },
    "11": {
      "lat": 52.34337612375032,
      "long": 4.888238811682442,
      "rotation": -2.5,
      "bearing": 141.2,
      "building": 8,
      "level": 0
    },
    "12": {
      "lat": 52.342679076470716,
      "long": 4.886921036031765,
      "rotation": -1.6,
      "bearing": 93.2,
      "building": 8,
      "level": 0
    },
    "13": {
      "lat": 52.34076464918098,
      "long":  4.88584073306842,
      "rotation": -0.01,
      "bearing": 7.9,
      "building": 8,
      "level": 0
    },
    "14": {
      "lat": 52.34153488944142,
      "long": 4.887946330152886,
      "rotation": 1.2,
      "bearing": -70,
      "building": 8,
      "level": 1
    },
    "15": {
      "lat": 52.34153488944142,
      "long": 4.887946330152886,
      "rotation": 1.2,
      "bearing": -70,
      "building": 8,
      "level": 1
    },
    "16": {
      "lat": 52.340483321407646,
      "long": 4.8894760863031195,
      "rotation": 1.5,
      "bearing": -84.2,
      "building": 8,
      "level": 1
    }
  };
  
  const { map } = useMap();
  const teaLogService = new TeaLogService();

  const [isActive, setIsActive] = useState(true);
  const [timer, setTimer] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isZonesInfoPopulated, setIsZonesInfoPopulated] = useState(false);

  const [zonesInfo, setZonesInfo] = useState([]);
  const [hubwalksInfo, setHubwalksInfo] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [recommendationsInfo, setRecommendationsInfo] = useState([]);
  const [attendeeUserToken, setAttendeeUserToken] = useState([]);
  const [attendeeInfo, setAttendeeInfo] = useState([]);

  const hasPassedDestFidRef = useRef(false);

  const timerRef = useRef(null);
  const [popupState, setPopupState] = useState({ poi: null, coordinates: null }); // Add state for the popup
  const [ciscoSpacesPopup, setCiscoSpacesPopup] = useState(false);
  const [showPopupCard, setShowPopupCard] = useState(false);
  const [showFaqList, setShowFaqList] = useState(false);
  const [qrScanBadgeInfoInput, setQRScanBadgeInfoInput] = useState(null);
  const [aiLoader, showAiLoader] = useState(false);
  const [showFailedPopupCard, setShowFailedPopupCard] = useState(false);

  
  
  const [showAICard, setShowAICard] = useState(false);

  const [isPointrSDKLoaded, setIsPointrSDKLoaded] = useState(false); // State to track Pointr SDK load status
  const [triggerDirectionsPopup, setTriggerDirectionsPopup] = useState(false);

  const tenantId = "263253";
  const locationId = "d4ac6990-d57f-4533-8904-158ccd2794c5";
  const siteExternalIdentifier = "f2a0ba12-1e7f-4605-8568-aed54c4f8974";
  const timeZoneCity = "Amsterdam";
  const timeZone = "Europe/Amsterdam";
  const qrCodeExpiresAt = 1743465599 // March 31 2025
  const eventLogoName = "cisco-live.svg"
  const eventName = "CLEMEA"
  const eventId="ds1ciscolive25emea";
  const eventTitle = "Amsterdam"
  const defaultBuildingTitle = "RAI Amsterdam"


  
  const eventSubTitle = ""

  const buildingInfo = [
    {
      "buildingTitle": "Amsterdam 2025",
      "buildingInternalIdentifier": 8,
      "buildingExternalIdentifier": "d4ac6990-d57f-4533-8904-158ccd2794c5",
      "buildingExtraData": {
          "timeZone": "Europe/Amsterdam"
      },
      "geometry": {
          "type": "Polygon",
          "coordinates": [
              [
                  [
                      4.88599397,
                      52.34407845
                  ],
                  [
                      4.89051515,
                      52.34382779
                  ],
                  [
                      4.89023767,
                      52.34162161
                  ],
                  [
                      4.89062198,
                      52.34155831
                  ],
                  [
                      4.89053026,
                      52.34058261
                  ],
                  [
                      4.89127334,
                      52.34051134
                  ],
                  [
                      4.89110501,
                      52.33917599
                  ],
                  [
                      4.88787683,
                      52.33943018
                  ],
                  [
                      4.88474856,
                      52.33990242
                  ],
                  [
                      4.88493035,
                      52.34106149
                  ],
                  [
                      4.88636647,
                      52.34102106
                  ],
                  [
                      4.88651192,
                      52.34212302
                  ],
                  [
                      4.88576665,
                      52.34216124
                  ],
                  [
                      4.88599397,
                      52.34407845
                  ]
              ]
          ]
      },
      "levels": [
          {
              "levelShortTitle": "GF",
              "levelLongTitle": "Ground Floor",
              "levelIndex": 0,
              "levelExtraData": {
                  "timeZone": "Europe/Amsterdam",
                  "levelExternalIdentifier": "bae17f92-ca21-4dba-808d-60426abdaac5"
              }
          },
          {
              "levelShortTitle": "L1",
              "levelLongTitle": "Level 1",
              "levelIndex": 1,
              "levelExtraData": {
                  "timeZone": "Europe/Amsterdam",
                  "levelExternalIdentifier": "8930d9b7-9cd9-408b-90ed-525b5ccc998f"
              }
          },
          {
              "levelShortTitle": "L2",
              "levelLongTitle": "Level 2",
              "levelIndex": 2,
              "levelExtraData": {
                  "timeZone": "Europe/Amsterdam",
                  "levelExternalIdentifier": "fce4a825-023d-4faa-87ca-25c308ec1e39"
              }
          },
          {
              "levelShortTitle": "L3",
              "levelLongTitle": "Level 3",
              "levelIndex": 3,
              "levelExtraData": {
                  "timeZone": "Europe/Amsterdam",
                  "levelExternalIdentifier": "82db86e1-e238-4f61-a55d-adfec0a31390"
              }
          },
          {
              "levelShortTitle": "L4",
              "levelLongTitle": "Level 4",
              "levelIndex": 4,
              "levelExtraData": {
                  "timeZone": "Europe/Amsterdam",
                  "levelExternalIdentifier": "c071410b-aec9-4b6d-9fed-4b3b9a63703e"
              }
          },
          {
              "levelShortTitle": "L5",
              "levelLongTitle": "Level 5",
              "levelIndex": 5,
              "levelExtraData": {
                  "timeZone": "Europe/Amsterdam",
                  "levelExternalIdentifier": "7080767a-9115-41ba-a00f-785093b79b63"
              }
          }
      ]
  }
];
  const buildingExID = {
    8: "d4ac6990-d57f-4533-8904-158ccd2794c5",
  };
  const levelExId ={
    "d4ac6990-d57f-4533-8904-158ccd2794c5":{
      0: "bae17f92-ca21-4dba-808d-60426abdaac5",
      1: "8930d9b7-9cd9-408b-90ed-525b5ccc998f",
      2: "fce4a825-023d-4faa-87ca-25c308ec1e39",
      3: "82db86e1-e238-4f61-a55d-adfec0a31390",
      4: "c071410b-aec9-4b6d-9fed-4b3b9a63703e",
      5: "7080767a-9115-41ba-a00f-785093b79b63"
    }
  }

  const minMaxBounds = [
    
    [ 4.874747373780906,
      52.32649719537784
    ],
    [
      4.900164974144076,
      52.35777235234855
    ]
  ];

  const recommendations = {
    "chat_conversation_id": "5cf6c049-4b71-4bb5-84e2-9f0cf31978c1",
    "chat_request_id": "15455224186270017tXU1733420183587",
    "user": "1545523959422001yb1Q",
    "recommended_schedule": [
      {
        "recommendationScore": 500.0,
        "sessionId": "1721152850135001lo0S",
        "abbreviation": "BRKSEC-2889",
        "title": "Mastering ISE Upgrades: Best Practices, Tips, and Tricks  ",
        "sessionTimeId": "1730200769192001sYC8",
        "startDateTime": "2025-02-12T07:00:00.000Z",
        "endDateTime": "2025-02-12T08:00:00.000Z",
        "length": 60,
        "type": "Breakout"
      },
      {
        "recommendationScore": 500.0,
        "sessionId": "1719263917516001Klz3",
        "abbreviation": "BRKSEC-2144",
        "title": "Modern Authentication Explained to the Network Professional",
        "sessionTimeId": "1734447812340001A2w9",
        "startDateTime": "2025-02-12T08:30:00.000Z",
        "endDateTime": "2025-02-12T10:00:00.000Z",
        "length": 90,
        "type": "Breakout"
      },
      {
        "recommendationScore": 420.0,
        "sessionId": "1733403756042001Bd2M",
        "abbreviation": "KDDSEC-1000",
        "title": "Security Keynote Deep Dive",
        "sessionTimeId": "1733404177768001dFFz",
        "startDateTime": "2025-02-12T10:15:00.000Z",
        "endDateTime": "2025-02-12T11:15:00.000Z",
        "length": 60,
        "type": "Keynote Deepdive"
      },
      {
        "recommendationScore": 500.0,
        "sessionId": "1733943680672001Myjt",
        "abbreviation": "BRKSEC-2887",
        "title": "Unify Threat Detection, Investigation, and Response with Splunk Enterprise Security and SOAR",
        "sessionTimeId": "1733944458145001M5k0",
        "startDateTime": "2025-02-12T11:30:00.000Z",
        "endDateTime": "2025-02-12T13:00:00.000Z",
        "length": 90,
        "type": "Breakout"
      },
      {
        "recommendationScore": 350.0,
        "sessionId": "1730808294784001mF8u",
        "abbreviation": "PSOMSI-1802",
        "title": "Proactive and AI-driven Managed Campus: A Smarter Approach for Business Continuity",
        "sessionTimeId": "1732294109377001SYr2",
        "startDateTime": "2025-02-12T13:10:00.000Z",
        "endDateTime": "2025-02-12T13:40:00.000Z",
        "length": 30,
        "type": "Product or Strategy Overview"
      },
      {
        "recommendationScore": 490.0,
        "sessionId": "1734599745697001R3s9",
        "abbreviation": "TSCSEC-2281",
        "title": "Secure Web Appliance and Umbrella Integration and troubleshooting",
        "sessionTimeId": "1734868801304001rr3u",
        "startDateTime": "2025-02-12T13:45:00.000Z",
        "endDateTime": "2025-02-12T14:15:00.000Z",
        "length": 30,
        "type": "Technical Solutions Clinic"
      },
      {
        "recommendationScore": 500.0,
        "sessionId": "1722001306824001HeQR",
        "abbreviation": "CSSSEC-1522",
        "title": "From Cost Center to Game Changer: The Journey of the IT department of LGI Logistics Group International GmbH with Cisco SD-WAN, Secure Firewall and SD-Access",
        "sessionTimeId": "1732658670019001JA5J",
        "startDateTime": "2025-02-12T14:15:00.000Z",
        "endDateTime": "2025-02-12T15:00:00.000Z",
        "length": 45,
        "type": "Customer Success Story"
      },
      {
        "recommendationScore": 500.0,
        "sessionId": "1721152033855001bknw",
        "abbreviation": "BRKSEC-1240",
        "title": "If You Don't Have a Security Reference Architecture, You Must Get One!",
        "sessionTimeId": "1730200120179001kkiw",
        "startDateTime": "2025-02-12T15:00:00.000Z",
        "endDateTime": "2025-02-12T16:00:00.000Z",
        "length": 60,
        "type": "Breakout"
      },
      {
        "recommendationScore": 500.0,
        "sessionId": "1721152036328001bC4x",
        "abbreviation": "BRKSEC-2239",
        "title": "Cisco Secure Firewall Platforms Deep Dive",
        "sessionTimeId": "1730201462635001soY4",
        "startDateTime": "2025-02-12T16:00:00.000Z",
        "endDateTime": "2025-02-12T17:30:00.000Z",
        "length": 90,
        "type": "Breakout"
      }
    ],
    "recommended_exhibitors": [
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1605283224811001sUtN",
        "url": "www.endace.com",
        "name": "Endace Europe Limited",
        "type": "Bronze Sponsor"
      },
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1732619165649001TRrR",
        "url": "https://learning.nil.com",
        "name": "NIL d.o.o.",
        "type": "Bronze Sponsor"
      },
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1711997070125001HLfp",
        "url": "www.netally.com",
        "name": "NetAlly, LLC",
        "type": "Silver Sponsor"
      },
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1727690904208001SjHk",
        "url": "www.cancom.at",
        "name": "CANCOM Austria AG",
        "type": "Silver Sponsor"
      },
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1446551914831001gX4Y",
        "url": "https://www.axians.com",
        "name": "Axians",
        "type": "Lounge Sponsor"
      },
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1416503060644001YYVC",
        "url": "www.se.com",
        "name": "SCHNEIDER ELECTRIC IT Corporation",
        "type": "Bronze Sponsor"
      },
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1416947286326001tP7x",
        "url": "https://www.lantronix.com/products-class/device-mgmt-oob/",
        "name": "Lantronix",
        "type": "Silver Sponsor"
      },
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1692001640521001Yd1N",
        "url": "www.bechtle.com",
        "name": "Bechtle Logistik & Service GmbH",
        "type": "Bronze Sponsor"
      },
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1567393122432001MNou",
        "url": "https://www.keysight.com",
        "name": "Keysight Technologies, Inc.",
        "type": "Bronze Sponsor"
      },
      {
        "recommendationScore": 140.0,
        "source": "exhibitor",
        "exhibitorId": "1416503058995001YzaE",
        "url": "https://www.netapp.com/data-storage/flexpod/",
        "name": "NetApp",
        "type": "Gold Sponsor"
      }
    ],
    "return_code": 0,
    "return_message": "Success",
    "error_detail": ""
  };
  const floorColourMapping = [
    { levelIndex: 0, color: '#0FC097' },
    { levelIndex: 1, color: '#FFB327' },
    { levelIndex: 2, color: '#0099F7' },
    { levelIndex: 3, color: '#FF468F' },
    { levelIndex: 4, color: '#8F29BE' },
    { levelIndex: 5, color: '#BBDFFF' },
  ];

  const constraintsLookUp = {
    "Full Conference": [
        "Full Conf - Schedule",
        "Explorer - Schedule"
    ],
    "Cisco Full Conference": [
        "Full Conf - Schedule",
        "Explorer - Schedule"
    ],
    "IT Leadership": [
        "Full Conf - Schedule",
        "Explorer - Schedule"
    ],
    "Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Cisco Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Cisco Thursday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Thursday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Cisco Wednesday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Wednesday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Event Staff": [
        "Full Conf - View"
    ],
    "Speaker Full Conference": [
        "Full Conf - View"
    ],
    "Cisco Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],

}  
 
  const getLocationId = (poi) => {
    const bid = poi?.properties?.bid;
    const lvl = poi?.properties?.lvl;
    const buildingId = buildingExID[bid];
  
    // Check if both buildingId and lvl exist in levelExId
    if (buildingId && lvl !== undefined && levelExId[buildingId]?.[lvl] !== undefined) {
      return levelExId[buildingId][lvl];
    }
  
    // Fallback to buildingId if lvl is not present or no match found
    return buildingId || locationId;
  };
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const kioskFid = query.get('kioskFid')||query.get('locationNum');
  const destFid = query.get('destFid');
  const latFromQuery = parseFloat(query.get('lat'));
  const longFromQuery = parseFloat(query.get('long'));
  const rotationFromQuery = parseFloat(query.get('rotation'));
  const zoomFromQuery = parseFloat(query.get('zoom'));
  const pitchFromQuery = parseFloat(query.get('pitch'));
  const bearingFromQuery = parseFloat(query.get('bearing'));
  const levelFromQuery = parseFloat(query.get('level'));
  const buildingFromQuery = parseFloat(query.get('building'));
  const visibleAiContainerFromQuery = query.get('enableAI');
  const eventStartDay = query.get('eventStartDay')?query.get('eventStartDay'):9;

  const centerLong = 4.888230470000053;
  const centerLat = 52.341674505000015;
  const xapi=null;

  const [lat, setLat] = useState(kioskData[kioskFid]?.lat || (latFromQuery ? latFromQuery :52.341674505000015));
  const [long, setLong] = useState(kioskData[kioskFid]?.long || (longFromQuery ? longFromQuery : 4.888230470000053));
  const [rotation, setRotation] = useState(kioskData[kioskFid]?.rotation || (rotationFromQuery ? rotationFromQuery:0));
  const [zoom, setZoom] = useState(kioskData[kioskFid]?.zoom || (zoomFromQuery ? zoomFromQuery : 17));
  const [pitch, setPitch] = useState(kioskData[kioskFid]?.pitch || (pitchFromQuery ? pitchFromQuery: 65));
  const [bearing, setBearing] = useState(kioskData[kioskFid]?.bearing || (bearingFromQuery ? bearingFromQuery : -70));
  const [level, setLevel] = useState(kioskData[kioskFid]?.level || (levelFromQuery ? levelFromQuery: 0));
  const [building, setBuilding] = useState(kioskData[kioskFid]?.building || (buildingFromQuery ? buildingFromQuery : 8));
  const [visibleAIContainer, setVisibleAIContainer] = useState(visibleAiContainerFromQuery?(visibleAiContainerFromQuery==='true'):true);

  const [whereAmILevel, setWhereAmILevel] = useState(level);
  const [whereAmIBuilding, setWhereAmIBuilding] = useState(building);
  const [selectedFloor, setSelectedFloor] = useState(level);
  const [kioskLocation, setKioskLocation] = useState(1);

  const [eventStartDateData, setEventStartDateData] = useState({
    year: 2025,
    month: 1,
    day: eventStartDay
  });


  const [eventEndDateData, setEventEndDateData] = useState({
    year: 2025,
    month: 1,
    day: 14
  });


  useEffect(() => {
    if (kioskFid && kioskData[kioskFid]) {
      const data = kioskData[kioskFid];
      setLat(data.lat || 52.341674505000015);
      setLong(data.long ||  4.888230470000053);
      setRotation(data.rotation || -1);
      setZoom(data.zoom || 17);
      setPitch(data.pitch || 65);
      setBearing(data.bearing || -70);
      setLevel(data.level || 0);
      setWhereAmILevel(level);
      setBuilding(data.building || 8);
      setWhereAmIBuilding(building);
    }
  }, [kioskFid]);


  useEffect(() => {
    if (destFid && !hasPassedDestFidRef.current) {
      // Call any function or set up as needed for the first time `destFid` is passed
      hasPassedDestFidRef.current = true;
    }
  }, [destFid]);

  
  const showPopup = (poi, coordinates, cleanPopup = true,isMapClick=false) => {
    if (!poi) {
      if(popupState.poi){
        handleResetView(cleanPopup,isMapClick);
      } 
      const navigationViewController = window.PointrWebSDK.MapWidget.getInstance()?.getUiController().getNavigationViewController();
      window.PointrWebSDK.MapWidget.getInstance().getUiController().getMapViewController().hideRoute();
      navigationViewController.close();
      setPopupState({ poi: null, coordinates: null });
    
     
    } else {
      setPopupState({ poi, coordinates: (poi?.geometry?.type === 'Point') ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]) });
    }
  };
  const showCiscoSpacesPopup = () => {
    setCiscoSpacesPopup(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.PointrWebSDK) {
        setIsPointrSDKLoaded(true);
        clearInterval(interval);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval);
  }, []);
  // Handler to hide the popup
  const hidePopup = () => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
    setTriggerDirectionsPopup(false);
  };
  
  const badgeQrScanSuccess = async (badgeId) => {
    try{
    console.log("Badge Id received " + badgeId);
    showPopup(null, null, true, false);
    setQRScanBadgeInfoInput(null);
    let accessToken = await CiscoAiAuth.fetchAccessTokenForAttendee(eventId, badgeId);
    const attendeeInfo = await fetchAttendeeInfo(accessToken, eventId, { badgeId: badgeId },teaLogService);
    const userKey=attendeeInfo?.[0]?.userKey;     
    //const userFullName=attendeeInfo?.[0]?.firstName+" "+attendeeInfo?.[0]?.lastName;
    //const firstName=attendeeInfo?.[0]?.firstName
    //const lastName=attendeeInfo?.[0]?.lastName     
    //const dashboardPackage=attendeeInfo?.[0]?.dashboardPackage;     


    teaLogService.overrideConfiguration({"data":{"user":attendeeInfo?.[0]}})
    teaLogService.teaLogBadgeScanAction(badgeId,kioskLocation?kioskLocation:1);
    sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0].userKey, "Badge Scan",eventName,attendeeInfo?.[0]);
    const recommendedEvents = await fetchRecommendedEvents(accessToken,eventId,attendeeInfo,"",constraintsLookUp,teaLogService);
    if(recommendedEvents == undefined|| recommendedEvents?.length==0){
      setShowFailedPopupCard(true);
      setShowPopupCard(false);
      return;
    }
    setRecommendationsInfo(recommendedEvents);
    setAttendeeInfo(attendeeInfo);
    setAttendeeUserToken(accessToken)
    setShowPopupCard(false);
    setShowAICard(true);
    }catch(error){
      console.log("Exception occured while processing badge");
      console.log(error)
      setShowFailedPopupCard(true);
      setShowPopupCard(false);
    }
    
  }

  useEffect(() => {
    // Fetch data from the API only once when the component mounts
    const getZones = async () => {
      try {

        const data = await fetchGsxData(tenantId);
        console.log("gsx" + data);
        if(data.zones){
          setIsZonesInfoPopulated(true);
        }
        setZonesInfo(data.zones);
        setHubwalksInfo(data.hubWalks);
        setFacilitiesData(data.facilities);

      } catch (err) {

        console.log(err);

      }
    };

    getZones();
    intialiseQRScannerModule();
  }, []);
  // Simulate map loading
  useEffect(() => {
    // Simulate a delay for the map loading
    const mapLoadTimeout = setTimeout(() => {
      setIsMapLoaded(true);
      document.title = '2025 Cisco Live';
    }, 5000); // Adjust the delay as needed

    return () => clearTimeout(mapLoadTimeout);
  }, []);

  useEffect(() => {
    let intervalId;
  
    const fetchPoiUntilFound = async () => {
      if (map && destFid) {
        const poi = await map.getPoiManager().get(destFid);
        if (poi) {
          // Uncomment this line when ready to show the popup
          const uiController = map.getUiController();
          const mapViewController = uiController.getMapViewController();
          mapViewController.setLevel(poi.properties.lvl);
          mapViewController.highlightPoi(poi);
           showPopup(poi, poi?.geometry?.type === 'Point' ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]),false,false);
           clearInterval(intervalId); // Stop checking once the poi is found
        }
        
      }
    };
  
    // Set up polling to run fetchPoiUntilFound every 500ms (adjust as needed)
    intervalId = setInterval(fetchPoiUntilFound, 5000);
  
    return () => clearInterval(intervalId); // Clear interval on component unmount or when dependencies change
  }, [map, destFid]);


  useEffect(() => {
    const eventTypes = ["click", "mousemove", "keypress", "wheel", "gesturestart", "gesturechange", "gestureend", "touchmove", "touchstart", "touchend"];

    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, handleActivity);
    });

    return () => {
      eventTypes.forEach(eventType => {
        window.removeEventListener(eventType, handleActivity);
      });
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => { }, [isActive]);


  const handleActivity = (event) => {
    setIsActive(true);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setIsActive(false);
      console.log("No activity detected for 2 minutes." + event.type);
    }, 180000); // 3 minutes
  };


  const handleSearch = async (searchInput) => {
    hidePopup();
    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');

    console.log(searchInput);
    let valueAfterTilde;
    let poiFid = searchInput?.fId;
    if (searchInput?.type === 'session') {
      poiFid = searchInput?.poiFId;
    }else if((searchInput?.type === 'exhibitor')){
      poiFid = searchInput?.poiFId;
    }
    if (poiFid?.includes('~')) {
      valueAfterTilde = poiFid.split('~')[1];
    } else {
      valueAfterTilde = poiFid;
    }
    const poi = await map.getPoiManager().get(valueAfterTilde);
    if (poi) {
      map.unhighlight();
      mapViewController.setLevel(poi.properties.lvl);
      mapViewController.highlightPoi(poi);
      map.hidePoiDetails(poi);

      if (poi?._geometry?.coordinates[0]) {
        const latLong = (poi.geometry.type === 'Point') ? {
          lat: poi.geometry.coordinates[1],
          lng: poi.geometry.coordinates[0]
        } : calculateMidPoint(poi?._geometry?.coordinates[0]);

        //updateLayerFilterForSingleFeature(mapView, searchInput);
        mapView.map.flyTo({
          center: [latLong.lng, latLong.lat],
          zoom: 20, pitch: pitch, bearing: bearing, speed: 0.4
        });
        if (searchInput.type === 'session') {
          poi._properties.isSession = true;
          poi._properties.sessionsInfo = [searchInput];
          poi._properties.sessionStart= searchInput?.utcStartTime;

        }
        showPopup(poi, latLong);
        manageLayers(map?.getUiController().getMapViewController().getView(),poi?._properties?.bid,poi?._properties?.lvl)
        if(poi?._properties?.lvl==whereAmILevel){
          markCurrentPosition(map, long, lat, rotation, true, pitch, bearing, whereAmIBuilding, whereAmILevel, whereAmIBuilding, whereAmILevel);

        }else{
          removeCurrentPosition(map.getUiController().getMapViewController().getView());
        }
      }
    }
  };

  useEffect(() => {
    if (searchInput) {
      handleSearch(searchInput);
    }
  }, [searchInput]);

  const intialiseQRScannerModule = async () =>{
      console.log("Initiliazing the QR code scanner");
      let hashes = getHashes(["username", "password", "ipAddress", "location"]);
      setKioskLocation(hashes?.location? hashes.location : 1)
      await initializeXAPIScanner(window.jsxapi,hashes,setQRScanBadgeInfoInput);
  }

  useEffect(() => {
    console.log("QR Input received "+qrScanBadgeInfoInput);
    if (qrScanBadgeInfoInput) {
      if(!showAICard && !showFaqList && showPopupCard){
        badgeQrScanSuccess(qrScanBadgeInfoInput);
        showAiLoader(true);
        setQRScanBadgeInfoInput(null);
      }else{
         console.error("Already Attendee is using the AI or no yet launched")
         setQRScanBadgeInfoInput(null);
      }
    }
  }, [qrScanBadgeInfoInput]);


  useEffect(() => {
    console.log("showAiLoader received "+aiLoader);
    if (aiLoader) {
      showLoader();
    }else{
      hideLoader();
    }
  }, [aiLoader]);


  useEffect(() => {
    console.log("showAICard received "+showAICard);
    if (!showAICard) {
      setShowFaqList(0);
      setQRScanBadgeInfoInput(null);
    }
  }, [showAICard]);

  // Function to create and show the loader
  function showLoader() {
    // Create and style the overlay element
    const overlay = document.createElement("div");
    overlay.id = "ai-overlay"; // Set the id
    overlay.className = "ai-overlay"; // Set the class name
    document.body.appendChild(overlay);

    // Create and style the loader element
    const loader = document.createElement("div");
    loader.id = "ai-loader"; // Set the id
    loader.className = "ai-loader"; // Set the class name
    document.body.appendChild(loader);

    // Display the loader and overlay
    if (loader && overlay) {
      overlay.style.display = "block";
      loader.style.display = "block";
    }
  }


  // Function to hide and remove the loader
  function hideLoader() {
    const loader = document.getElementById("ai-loader");
    const overlayloader = document.getElementById("ai-overlay");

    if (loader && overlayloader) {
      loader.style.display = "none";
      document.body.removeChild(loader);

      overlayloader.style.display = "none";
      document.body.removeChild(overlayloader);

    }
  }
  const handleResetView = (cleanPopup = false,isMapClick=false) => {
    setShowAICard(false);
    setShowFaqList(false);
    setTriggerDirectionsPopup(false);
    setSelectedFloor(whereAmILevel)
    handleReset(long ? long : 4.888230470000053, lat ? lat : 52.341674505000015, rotation ? rotation : -4.5, 18, true, cleanPopup,isMapClick, pitch, bearing, whereAmIBuilding, whereAmILevel, centerLong, centerLat);
  };


  const handleResetViewButton = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  const handleResetViewButtonInitialZoom = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    setSelectedFloor(whereAmILevel)
    handleResetView();
  //handleReset(long ? long : -115.1782890405586, lat ? lat : 36.088308032921276, rotation ? rotation : -4.5, 17, true, cleanPopup,false, pitch, bearing, building, level);

    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.map.flyTo({
      center: [long, lat],
      zoom: 18,
      bearing: bearing,
      pitch: pitch,
      speed:0.4
    });
  };

  return (
    <div className="main-container">

      {isPointrSDKLoaded && <MapComponent showPopup={showPopup} tenantId={tenantId}
        locationId={locationId} siteExternalIdentifier={siteExternalIdentifier} lat={lat} long={long} zoom={zoom} pitch={pitch} bearing={bearing} rotation={rotation} eventName = {eventName} whereAmILevel={parseInt(whereAmILevel)} whereAmIBuilding={parseInt(whereAmIBuilding)} defaultBuilding={parseInt(building)} defaultLevel={parseInt(level)} setBuilding={setBuilding} setLevel={setLevel} timeZone={timeZone} eventStartDateData={eventStartDateData} centerLatLong={[centerLong,centerLat]} minMaxBounds={minMaxBounds}
        setTriggerDirectionsPopup={setTriggerDirectionsPopup}/>}
      {isMapLoaded && (
        
        <>
          {handleWhereAmI(map,long,lat,rotation,pitch,bearing,whereAmILevel, whereAmIBuilding)}
          <HeaderContainer
            showCiscoSpacesPopup={showCiscoSpacesPopup} timeZoneCity={timeZoneCity} 
            eventStartDateData = {eventStartDateData} eventEndDateData={eventEndDateData}  
            handleResetView={handleResetViewButton}  eventLogoName={eventLogoName} 
            eventName={eventName} defaultLevel={parseInt(level)} 
            defaultBuilding = {parseInt(building)} setBuilding={setBuilding} 
            setLevel={setLevel} pitch={pitch} 
            bearing={bearing} lat={lat} long={long} 
            rotation={rotation}
            eventTitle={eventTitle}
            eventSubTitle={eventSubTitle}
            eventTimeZone={timeZone}
            defaultBuildingTitle={defaultBuildingTitle}
            buildingInfo={buildingInfo}/>
          {zonesInfo &&
            <LeftContainer
              zoneData={zonesInfo} showPopup={showPopup} hidePopup={hidePopup} tenantId={tenantId}
              locationId={locationId} qrCodeExpiresAt={qrCodeExpiresAt}
              whereAmIBuilding={whereAmIBuilding}
              whereAmILevel={whereAmILevel}
              defaultLevel={parseInt(level)} 
              defaultBuilding = {parseInt(building)}
              handleResetView={handleResetViewButton}
              setBuilding={setBuilding}
              setLevel={setLevel} 
              lat={lat}
              long={long}
              rotation={rotation}
              pitch={pitch}
              bearing={bearing}
              showLevelSelector={true}
              isZonesInfoPopulated={isZonesInfoPopulated}
              isDestFidPopulated={destFid?true:false}
              selectedFloor={selectedFloor}
              setSelectedFloor={setSelectedFloor}
              buildingInfo={buildingInfo}
              defaultBuildingTitle={defaultBuildingTitle}
              centerLatLong={[centerLong,centerLat]}
              eventName={eventName}
              floorColourMapping={floorColourMapping}
               />}
          {isPointrSDKLoaded && 
          <RightContainer
            hubwalksData={hubwalksInfo} 
            facilitiesData={facilitiesData} 
            pitch={pitch} 
            bearing={bearing} 
            centerLong={centerLong} 
            centerLat={centerLat}
            visibleAIContainer={visibleAIContainer}
            eventId={eventId}
            tenantId={tenantId}
            setShowPopupCard={setShowPopupCard}
            showAICard={showAICard}
            setShowAICard={setShowAICard}
            showPopup={showPopup}
            recommendationsInfo={recommendationsInfo}
            setRecommendationsInfo={setRecommendationsInfo}
            attendeeUserToken={attendeeUserToken}
            attendeeInfo={attendeeInfo}
            constraintsLookUp={constraintsLookUp}
            teaLogService={teaLogService}
            setShowFaqList={setShowFaqList}
            showFaqList={showFaqList}
            setTriggerDirectionsPopup={setTriggerDirectionsPopup}
            triggerDirectionsPopup={triggerDirectionsPopup}
            setPopupState={setPopupState}
            showAiLoader={showAiLoader}
            />}
          {(!isActive &&
            /**<CountdownTimer
              initialMinutes={0}
              initialSeconds={10}
              resetTimer={isActive} // Pass isActive as a prop to reset the timer
              showPopup={showPopup}
            />*/
            <Screensaver inactivityTimeout={1000} flipInterval={3000} showPopup={showPopup} ciscoSpacesPopupImage={ciscoSpacesPopupImage} lat={lat} long={long} zoom={zoom} rotation={rotation} pitch={pitch} bearing={bearing} whereAmILevel={whereAmILevel} centerLong={centerLong} centerLat={centerLat} setShowAICard={setShowAICard} setShowPopupCard={setShowPopupCard}/>

          )}
          {(showFaqList==1) && <AIFAQListContainer setShowFaqList={setShowFaqList} showFaqList={showFaqList} setShowAICard={setShowAICard} showAICard={showAICard} setShowPopupCard={setShowPopupCard}/>}
          {showSearch && (
            <SearchPopup
              setSearchInput={setSearchInput}
              setShowSearch={setShowSearch}
              map={map}
              tenantId={tenantId}
              placeholder={"Search for points of interest and other facilities"}
            />
          )}
          {showPopupCard && (
            <div className="ai-popup-overlay">
              <div className="ai-popup-overlay-popup">
                <div className="ai-popup-overlay-popup-card">
                 
                  <img src={ciscoAILogo} class="ai-popup-overlay-popup-card-logo" />
                  <div className="ai-popup-overlay-popup-card-logo-name">
                  You have activated <br/>Cisco AI Assistant.
                  </div>
                  <div className="ai-popup-overlay-popup-card-title">
                  Now, scan the front of your<br/>badge on the scanner to begin<br/>your customized journey.​
                  </div>
                  <img src={ciscoAIQrPlaceHolderLogo} className="ai-popup-overlay-popup-card-qr-place-holder"  />
                  <div className="ai-popup-overlay-popup-card-bottom-button">
                  <img src={ciscoHelpbutton} className="ai-popup-overlay-popup-card-bottom-button-help"  onClick={() => {setShowPopupCard(false);setShowFailedPopupCard(false); setShowFaqList(1); }}/>

                  <img src={ciscoClosebutton} className="ai-popup-overlay-popup-card-bottom-button-close" onClick={() => setShowPopupCard(false)}/>
                  </div>
                </div>
              </div>
            </div>
          )}
           {showFailedPopupCard && (
            <div className="ai-popup-overlay">
              <div className="ai-popup-overlay-popup">
                <div className="ai-popup-overlay-popup-card">
                 
                  <img src={ciscoAILogo} class="ai-popup-overlay-popup-card-logo" />
                  <div className="ai-popup-overlay-popup-card-logo-name">
                  You have activated <br/>Cisco AI Assistant.
                  </div>
                  <div className="ai-popup-overlay-popup-card-title">
                  There was an error<br/>scanning your badge.​
                  </div>
                  <img src={ciscoAIQrPlaceHolderFailedLogo} className="ai-popup-overlay-popup-card-qr-place-holder"/>
                  <div className="ai-popup-overlay-popup-card-bottom-scan-again-button" onClick={()=>{ setShowFailedPopupCard(false); setShowPopupCard(true);}}>
                    Scan again
                  </div>

                  <div className="ai-popup-overlay-popup-card-bottom-button">
                  <img src={ciscoHelpbutton} className="ai-popup-overlay-popup-card-bottom-button-help"  onClick={() => {setShowPopupCard(false); setShowFailedPopupCard(false); setShowFaqList(1)}}/>

                  <img src={ciscoClosebutton} className="ai-popup-overlay-popup-card-bottom-button-close" onClick={() => {setShowPopupCard(false); setShowFailedPopupCard(false);}}/>
                  </div>
                </div>
              </div>
            </div>
          )}
          {popupState.poi && (
            <CustomPopup
              poi={popupState.poi}
              coordinates={popupState.coordinates}
              tenantId={tenantId}
              locationId={getLocationId(popupState.poi)}
              onCancel={hidePopup}
              setPopupState={setPopupState}
              source={"poiClick"}
              lat={lat}
              long={long}
              building={building}
              level={level}
              rotation={rotation}
              bearing={bearing}
              pitch={pitch}
              whereAmIBuilding={whereAmIBuilding}
              whereAmIlevel={whereAmILevel}
              qrCodeExpiresAt={qrCodeExpiresAt}
              destFid={hasPassedDestFidRef.current ? undefined : destFid} 
              setBuilding={setBuilding}
              setLevel={setLevel}
              eventStartDate={eventStartDateData}
              eventTimeZone={timeZone}
              setSelectedFloor={setSelectedFloor}
              triggerDirectionsPopup={triggerDirectionsPopup}
              setTriggerDirectionsPopup={setTriggerDirectionsPopup}
            />
          )}
          {ciscoSpacesPopup && (
            <div className="cisco-spaces-popup-overlay" >
              <div className="cisco-spaces-popup-overlay">
                <div className="cisco-spaces-popup-overlay-popup">
                  <img src={ciscoSpacesPopupIcon} alt="Popup Content" class="cisco-spaces-popup-overlay-image" />
                  <div className="cisco-spaces-popup-overlay-close" onClick={() => setCiscoSpacesPopup(false)}>
                    <svg width="44" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_11306_4187)">
                        <circle cx="22.5477" cy="22.1922" r="21.1342" stroke="white" stroke-width="0.98722" />
                        <path d="M29.4141 26.9647L24.6432 22.1929L29.4141 17.4216L27.3198 15.3271L22.5486 20.0983L17.7775 15.3271L15.683 17.4216L20.4544 22.1929L15.6821 26.9647L17.7764 29.0589L22.5486 24.287L27.3198 29.0589L29.4141 26.9647Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_11306_4187">
                          <rect width="43.2556" height="43.2556" fill="white" transform="translate(0.919922 0.564453)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          )}

          { aiLoader && <div  class="ai-overlay" id="ai-overlay" >
                    <div class="ai-loader" id="ai-loader" ></div>
                </div>}
          <FooterContainer
            setShowSearch={setShowSearch}
            handleResetView={handleResetViewButtonInitialZoom}
            long={long}
            lat={lat}
            rotation={rotation}
            pitch={pitch}
            bearing={bearing}
            whereAmIBuilding={parseInt(whereAmIBuilding)}
            whereAmILevel={parseInt(whereAmILevel)}
            setSelectedFloor={setSelectedFloor}
          />
        </>
      )}
    </div>
  );
};

export default CiscoLiveEMEAMainContainer;
