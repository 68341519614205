let keyCombo = []; // Buffer for storing key combos: eg. Shift + A
let keySequence = []; // Buffer for storing key sequence until an 'Enter' key is received
let keyCode = [];
let retries = 0;
const maxRetries = 5; // Max number of retries before giving up
const retryInterval = 5000; // Retry interval in ms (5 seconds) 
let deviceName = "";
let xapi = null;
let connecting = false;

export function getHashes(required) {
    if (!window.location.hash) return;
    let hashes = {};
    let splitHash = window.location.hash.slice(1, window.location.hash.length);
    splitHash = splitHash.split("&");
    for (let i = 0; i < splitHash.length; i++) {
        let hash = splitHash[i].split("=");
        if (hash.length == 2) {
            hashes[hash[0]] = hash[1];
        }
    }

    if (required.every((key) => Object.keys(hashes).includes(key))) {
        console.log("All required parameters found");
        return hashes;
    }

    console.log("Missing Parameters");
    return null;
}
export async function getXAPI(jsxapi, hashes, setQRScanBadgeInfoInput) {
    return new Promise(resolve => {
        jsxapi
            .connect(hashes.ipAddress, {
                username: hashes.username,
                password: hashes.password
            })
            .on('error', (err) => {
                //resetToHome();
                console.error('Error connecting to xAPI:', err);
                resolve(null);
            })
            .on('ready', async (xapiConnection) => {
                try {
                    console.log('xAPI connection established');
                    // Enable USB keyboard input support on Webex device
                    xapiConnection.Config.Peripherals.InputDevice.Mode.set('On');

                    // Subscribe to key action events (will capture keystrokes from barcode scanner)
                    xapiConnection.Event.UserInterface.InputDevice.Key.Action.on((event) =>
                        processKeyEvents(event, setQRScanBadgeInfoInput)
                    );

                    xapiConnection.Status.RoomAnalytics.Engagement.CloseProximity
                        .on(value => console.log("CloseProximity : " + value));

                    resolve(xapiConnection);
                } catch (error) {
                    console.log("Exception occured while creating connection for QR code scanner")
                    console.error('Fetch error:', error);
                }
            });
    });
}
async function ensureConnection(jsxapi, hashes, setQRScanBadgeInfoInput) {
    try {
        // If connection fails, retry after some time
        while (!xapi && retries < maxRetries) {
            retries++;
            console.log(`Attempting connection... Attempt ${retries}/${maxRetries}`);

            // Wait for the connection attempt before continuing
            await new Promise(resolve => {
                setTimeout(async () => {
                    // Try to establish the connection
                    try {
                        xapi = await getXAPI(jsxapi, hashes, setQRScanBadgeInfoInput);
                        resolve();  // Resolve the promise once the connection attempt is done
                    } catch (error) {
                        console.error('Error establishing xAPI connection:', error);
                        resolve();  // Resolve even if there's an error, so the loop can continue
                    }
                }, retryInterval);
            });

            return xapi;
        }
        if (retries >= maxRetries) {
            //Resetting Retry Counter
            retries = 0;
            console.error('Max retries reached. Connection failed.');
        }
    } catch (error) {
        console.error('Error in ensuring connection:', error);
    }
}

// Function to initialize the connection to the Webex Board and start listening for barcode scans
export async function initializeXAPIScanner(jsxapi, hashes, setQRScanBadgeInfoInput) {
    if (connecting)
        return;
    if (!xapi) {
        connecting = true;
        console.log("Establishing connection to WebEx Device...");
        xapi = await ensureConnection(jsxapi, hashes, setQRScanBadgeInfoInput);
        if (!xapi) {
            console.error("Error initializing scanner");
            return;
        }
        const deviceName = await xapi.Status.UserInterface.ContactInfo.Name.get();

        console.log("Scanner initialized and listening for input on ", deviceName);
    }
    else if (xapi !== undefined && xapi.readyState === xapi.OPEN) {
        console.log("Reusing connection for input on ", deviceName);

    } else {
        console.error("Error initializing scanner");
        xapi = null;
    }
    return xapi;
}

export async function processKeyEvents(event, setQRScanBadgeInfoInput) {

    const key = event.Key.split("_").pop().toLowerCase();

    // Store all new keys except an 'enter' key to the keyCombo array
    if (event.Type == "Pressed" && key !== "enter") {
        keyCombo.push(key);
        return;
    }

    // Transfer keyCombos to KeySequence and handle uppercasing
    if (event.Type == "Released" && key !== "enter") {
        if (keyCombo.length == 2 && keyCombo[0].endsWith("shift")) {
            console.log("Storing Key:", key);
            keySequence.push(keyCombo.pop().toUpperCase());
            keyCombo = [];
        } else {
            console.log("Adding to sequence Key:", keyCombo);
            keySequence.push(keyCombo.pop());
        }
        return;
    }

    // Once 'enter' released detected, output the collected key sequence and reset buffer
    if (event.Type == "Released" && key == "enter") {
        const keyCode = keySequence.join("");
        console.log('New Badge Scan Received:', keyCode)
        let result = { data: keyCode };
        await setResult(result, setQRScanBadgeInfoInput);
        await resetArrays(setQRScanBadgeInfoInput);

    }
}

export async function resetArrays(setQRScanBadgeInfoInput) {
    keySequence = [];
    keyCombo = [];
    keyCode = [];
    console.log('Arrays Cleared:', { keySequence, keyCombo, keyCode });
   
}

export async function setResult(result, setQRScanBadgeInfoInput) {
    const eventIdRegex = /^[a-zA-Z0-9]{20}/;
    const resultStringArray = result.data.match(eventIdRegex);
    console.log("Badge ID", resultStringArray[0]);
    await setQRScanBadgeInfoInput(prev => {
        console.log("Previous:", prev, "New:", null);
        return null;
      })
     await setQRScanBadgeInfoInput(prev => {
        console.log("Previous:", prev, "New:", resultStringArray[0]);
        return resultStringArray[0];
      })

}